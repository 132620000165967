import {
  GET_GLOBAL_PARAMS_LEADS,
  GET_GLOBAL_PARAMS_LEADS_FAILURE,
  GET_GLOBAL_PARAMS_LEADS_SUCCESS,
  GET_GLOBAL_PARAMS_PIPELINE,
  GET_GLOBAL_PARAMS_PIPELINE_FAILURE,
  GET_GLOBAL_PARAMS_PIPELINE_SUCCESS,
  GlobalsDispatchTypes,
  GlobalParams,
  GET_GLOBAL_PARAMS_PROJECTS,
  GET_GLOBAL_PARAMS_PROJECTS_SUCCESS,
  GET_GLOBAL_PARAMS_PROJECTS_FAILURE,
  GET_GLOBAL_PARAMS_LOCATIONS,
  GET_GLOBAL_PARAMS_LOCATIONS_SUCCESS,
  GET_GLOBAL_PARAMS_LOCATIONS_FAILURE
} from "./globals-types";

interface DefaultStateI {
  loadingLeadsGlobalParams: boolean;
  errorLeadsGlobalParams?: boolean;
  messagesLeadsGlobalParams?: any;

  leadsGlobalParams: GlobalParams;

  loadingPipelineGlobalParams: boolean;
  errorPipelineGlobalParams?: boolean;
  messagesPipelineGlobalParams?: any;

  pipelineGlobalParams: GlobalParams;

  loadingProjectsGlobalParams: boolean;
  errorProjectsGlobalParams?: boolean;
  messagesProjectsGlobalParams?: any;

  projectsGlobalParams: GlobalParams;

  loadingLocationsGlobalParams: boolean;
  errorLocationsGlobalParams?: boolean;
  messagesLocationsGlobalParams?: any;

  locationsGlobalParams: GlobalParams;
}

const defaultState: DefaultStateI = {
  loadingLeadsGlobalParams: false,
  leadsGlobalParams: {
    data: [],
  },

  loadingPipelineGlobalParams: false,
  pipelineGlobalParams: {
    data: [],
  },

  loadingProjectsGlobalParams: false,
  projectsGlobalParams: {
    data: [],
  },

  loadingLocationsGlobalParams: false,
  locationsGlobalParams: {
    data: [],
  },
};

const globalsReducer = (state: DefaultStateI = defaultState, action: GlobalsDispatchTypes): DefaultStateI => {
  switch (action.type) {
    case GET_GLOBAL_PARAMS_LEADS:
      return {
        ...state,
        loadingLeadsGlobalParams: true,
      };
    case GET_GLOBAL_PARAMS_LEADS_SUCCESS:
      return {
        ...state,
        loadingLeadsGlobalParams: false,
        leadsGlobalParams: action.payload,
      };
    case GET_GLOBAL_PARAMS_LEADS_FAILURE:
      return {
        ...state,
        loadingLeadsGlobalParams: false,
        messagesLeadsGlobalParams: action.message,
      };
    case GET_GLOBAL_PARAMS_PIPELINE:
      return {
        ...state,
        loadingPipelineGlobalParams: true,
      };
    case GET_GLOBAL_PARAMS_PIPELINE_SUCCESS:
      return {
        ...state,
        loadingPipelineGlobalParams: false,
        pipelineGlobalParams: action.payload,
      };
    case GET_GLOBAL_PARAMS_PIPELINE_FAILURE:
      return {
        ...state,
        loadingPipelineGlobalParams: false,
        messagesPipelineGlobalParams: action.message,
      };
    case GET_GLOBAL_PARAMS_PROJECTS:
      return {
        ...state,
        loadingProjectsGlobalParams: true,
      };
    case GET_GLOBAL_PARAMS_PROJECTS_SUCCESS:
      return {
        ...state,
        loadingProjectsGlobalParams: false,
        projectsGlobalParams: action.payload,
      };
    case GET_GLOBAL_PARAMS_PROJECTS_FAILURE:
      return {
        ...state,
        loadingProjectsGlobalParams: false,
        messagesProjectsGlobalParams: action.message,
      };
    case GET_GLOBAL_PARAMS_LOCATIONS:
      return {
        ...state,
        loadingLocationsGlobalParams: true,
      };
    case GET_GLOBAL_PARAMS_LOCATIONS_SUCCESS:
      return {
        ...state,
        loadingLocationsGlobalParams: false,
        locationsGlobalParams: action.payload,
      };
    case GET_GLOBAL_PARAMS_LOCATIONS_FAILURE:
      return {
        ...state,
        loadingLocationsGlobalParams: false,
        messagesProjectsGlobalParams: action.message,
      };
    default:
      return state;
  }
};

export default globalsReducer;