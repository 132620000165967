import moment from "moment";
import { NotificationContentInterface } from "./notification.interface";
import { WORKSPACE } from "src/utils/constants/global.constants";
import { Link } from "react-router-dom";
import {
    NOTIFICATION_BULK_LEAD_ASSIGNMENT,
    NOTIFICATION_IMPORT_LEADS,
    NOTIFICATION_IMPORT_LEADS_SUCCEED,
    NOTIFICATION_LEAD_ASSIGNMENT,
    NOTIFICATION_REMINDER
} from "src/utils/constants/notifications.constant";
import {
    BulkFailedIcon,
    BulkSuccessIcon,
    DotIcon,
    EventIcon,
    SwitchUserIcon
} from "../icons/icon";
import { downloadFileStorageService } from "src/redux/globals/globals-service";


const NotificationContent: React.FC<NotificationContentInterface> = ({
    data,
    i,
    handleNotification
}: NotificationContentInterface) => {
    const getFailedFile = async (failed_path) => {
        const filename = failed_path.split('/');
        const res: any = await downloadFileStorageService({
            filepath: failed_path
        });
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename[filename.length - 1]);

        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    const bucket = data?.data?.lead ? WORKSPACE.find(w => w.id === data?.data?.lead?.workspace_id) : undefined;

    switch (data.type_id) {
        case NOTIFICATION_REMINDER:
            return (
                <div
                    className="notification-drawer-body-container"
                    onClick={() => {
                        handleNotification(data);
                    }}
                >
                    <Link to={`/${bucket?.url}?id=${data?.data?.lead?.uuid}`}>
                        <div className="notification-drawer-body-container-item">
                            <div className="notification-drawer-body-container-item-left">
                                {data?.is_read ? (
                                    <span className="status-read"></span>
                                ) : (
                                    <span className='status-unread'><DotIcon color='#3A6ACD' /></span>
                                )}
                                <span className="icon"><EventIcon size={24} color='#3A6ACD' /></span>
                                <span className="title">{data?.type_name}</span>
                            </div>
                            <div className="notification-drawer-body-container-item-right">
                                <span className="time">{moment(data?.created_at).fromNow()}</span>
                            </div>
                        </div>
                        <div className="notification-drawer-body-container-desc">
                            <span className="description">
                                {data?.data?.activity?.title}
                                <span className="default"> in {moment(data?.data?.activity?.due_date).fromNow()} for </span>
                                <span className="name">
                                    <Link to={`/${bucket?.url}?id=${data?.data?.lead?.uuid}`}>
                                        {` ${data?.data?.lead?.name}`}
                                    </Link>
                                </span>
                            </span>
                        </div>
                    </Link>
                </div>
            )
        case NOTIFICATION_IMPORT_LEADS:
            return (
                <div
                    className="notification-drawer-body-container notification-drawer-body-container-no-ancor"
                    onClick={() => {
                        handleNotification(data);
                    }}
                >
                    <div className="notification-drawer-body-container-item">
                        <div className="notification-drawer-body-container-item-left">
                            {data?.is_read ? (
                                <span className="status-read"></span>
                            ) : (
                                <span className='status-unread'><DotIcon color='#3A6ACD' /></span>
                            )}
                            <span className="icon">
                                {data?.data?.status === NOTIFICATION_IMPORT_LEADS_SUCCEED ? <BulkSuccessIcon /> : <BulkFailedIcon />}
                            </span>
                            <span className="title">
                                {data?.type_name}
                                {data?.data?.status === NOTIFICATION_IMPORT_LEADS_SUCCEED ? ` - Success` : ` - Failed`}
                            </span>
                        </div>
                        <div className="notification-drawer-body-container-item-right">
                            <span className="time">{moment(data?.created_at).fromNow()}</span>
                        </div>
                    </div>
                    <div className="notification-drawer-body-container-desc">
                        <span className="description">
                            {data?.data?.file_name}
                            {data?.data?.status === NOTIFICATION_IMPORT_LEADS_SUCCEED ?
                                <span className="default">{` successfully imported, thank you`}</span>
                                : (
                                    <>
                                        <span className="default">{` ${data?.data?.message} `}</span>
                                        <span
                                            className="name"
                                            onClick={() => {
                                                getFailedFile(data?.data?.failed_file_path);
                                                handleNotification(data);
                                            }}
                                        >Report</span>
                                    </>
                                )}
                        </span>
                    </div>
                </div>
            )
        case NOTIFICATION_LEAD_ASSIGNMENT:
            return (
                <div
                    className="notification-drawer-body-container"
                    onClick={() => {
                        handleNotification(data);
                    }}
                >
                    <div className="notification-drawer-body-container-item">
                        <div className="notification-drawer-body-container-item-left">
                            {data?.is_read ? (
                                <span className="status-read"></span>
                            ) : (
                                <span className='status-unread'><DotIcon color='#3A6ACD' /></span>
                            )}
                            <span className="icon"><SwitchUserIcon size={24} color='#3A6ACD' /></span>
                            <span className="title">{data?.type_name}</span>
                        </div>
                        <div className="notification-drawer-body-container-item-right">
                            <span className="time">{moment(data?.created_at).fromNow()}</span>
                        </div>
                    </div>
                    <div className="notification-drawer-body-container-desc">
                        <span className="description">
                            {data?.sender?.name}{' '}
                            <span className="default">{' '}has been assign</span>
                            <span className="name">
                                <Link to={`/${bucket?.url}?id=${data?.data?.lead?.uuid}`}>
                                    {` ${data?.data?.lead?.name}`}
                                </Link>
                            </span>
                            <span className="default">{' '}to you</span>
                        </span>
                    </div>
                </div>
            )
        case NOTIFICATION_BULK_LEAD_ASSIGNMENT:
            return (
                <div
                    className="notification-drawer-body-container"
                    onClick={() => {
                        handleNotification(data);
                    }}
                >
                    <div className="notification-drawer-body-container-item">
                        <div className="notification-drawer-body-container-item-left">
                            {data?.is_read ? (
                                <span className="status-read"></span>
                            ) : (
                                <span className='status-unread'><DotIcon color='#3A6ACD' /></span>
                            )}
                            <span className="icon"><SwitchUserIcon size={24} color='#3A6ACD' /></span>
                            <span className="title">{data?.type_name}</span>
                        </div>
                        <div className="notification-drawer-body-container-item-right">
                            <span className="time">{moment(data?.created_at).fromNow()}</span>
                        </div>
                    </div>
                    <div className="notification-drawer-body-container-desc">
                        <span className="description">
                            {data?.sender?.name}{' '}
                            <span className="default">{` has been assign ${data?.data?.total_lead_assigns} leads to you`}</span>
                        </span>
                    </div>
                </div>
            )
        default:
            return (<></>)
    }
}
export default NotificationContent;