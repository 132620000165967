import ApiClient from 'src/utils/api-client';

const loginService = payload => ApiClient.post(`/auth/login`, payload, null).then(result => result?.data);
const logoutService = () => ApiClient.post(`/auth/logout`, null, null).then(result => result?.data);
const getUserInfoService = () => ApiClient.withAuth().then(api => api.get(`/user-preference/user/info`, null, null).then(result => result?.data));


export {
    loginService,
    logoutService,
    getUserInfoService
};
