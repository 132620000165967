import { notification as snackbar } from 'antd';
import { CheckFilledIcon } from '../icons/icon';
import {
    CloseCircleFilled,
    ExclamationCircleFilled
} from '@ant-design/icons';

import 'src/assets/css/snackbar.scss';
import { TYPE_ALERT_ERROR, TYPE_ALERT_SUCCESS } from 'src/utils/constants/global.constants';

const Snackbar = props => {
    const { message, alert } = props;
    return snackbar.open({
        ...props,
        className: alert ? `snackbar snackbar-${alert}` : 'snackbar',
        message: message,
        placement: 'bottomLeft',
        icon: alert && (alert === TYPE_ALERT_SUCCESS) && <CheckFilledIcon color='#008844' /> || alert && (alert === TYPE_ALERT_ERROR) && <CloseCircleFilled style={{ fontSize: '16px', color: '#d01a22' }} /> || alert && <ExclamationCircleFilled style={{ fontSize: '16px', color: '#868d9a' }} />
    });
};

export default Snackbar;
