import {
  OpportunitiesDispatchTypes,
  OpportunitiesType,
  GET_OPPORTUNITIES,
  GET_OPPORTUNITIES_FAILURE,
  GET_OPPORTUNITIES_SUCCESS,
  GET_OPPORTUNITIES_FILTER,
} from './opportunities-types';

interface DefaultStateI {
  loading: boolean;
  error?: boolean;
  messages?: any;

  opportunities: OpportunitiesType;
  filterQuery: any;
}

const defaultState: DefaultStateI = {
  loading: false,
  opportunities: {
    data: [],
  },
  filterQuery: {},
};

const opportunitiesReducer = (state: DefaultStateI = defaultState, action: OpportunitiesDispatchTypes): DefaultStateI => {
  switch (action.type) {
    case GET_OPPORTUNITIES:
      return {
        ...state,
        loading: true,
      };
    case GET_OPPORTUNITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        opportunities: action.payload,
      };
    case GET_OPPORTUNITIES_FAILURE:
      return {
        ...state,
        loading: false,
        messages: action.message,
      };
    case GET_OPPORTUNITIES_FILTER:
      return {
        ...state,
        filterQuery: action.payload,
      };
    default:
      return state;
  }
};

export default opportunitiesReducer;
