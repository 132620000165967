import packageJson from "../../../package.json";
import { Dispatch } from 'redux';
import {
  USER_LOGIN,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGOUT,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAILURE,
  UserDispatchTypes,
  GET_USER_INFO,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAILURE,
} from './auth-types';
import { getUserInfoService, loginService, logoutService } from './auth-service';
import { setUserData, clearAll, setUserToken, setVersionApp } from 'src/utils/storage';
import { AUTH_ERROR_NOT_FOUND, AUTH_ERROR_NOT_MATCH, SUCCESS } from 'src/utils/constants/global.constants';

export const userLogin = (user: any) => async (dispatch: Dispatch<UserDispatchTypes>) => {
  try {
    dispatch({
      type: USER_LOGIN,
      error: false,
    });

    const res: any = await loginService(user);
    if (res) {
      if (res.code === SUCCESS) {
        setVersionApp(packageJson.buildDate)
        const token = {
          access_token: res?.data?.access_token,
          refresh_token: res?.data?.refresh_token,
        }
        setUserToken(token);
        const userInfo = await getUserInfoService();
        if (userInfo?.code === SUCCESS) {
          const users = {
            isLoggedIn: true,
            email: userInfo?.data?.email,
            name: userInfo?.data?.name,
            roles: userInfo?.data?.role
          };
          setUserData(users);
          dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: {
              token: token.access_token,
              refresh_token: token.refresh_token,
              user_info: userInfo?.data,
            },
            messages: null,
          });
        }
      } else {
        dispatch({
          type: USER_LOGIN_FAILURE,
          error: true,
          messages: { message: 'Network Failure' },
        });
      }
    } else {
      dispatch({
        type: USER_LOGIN_FAILURE,
        error: true,
        messages: { message: 'Network Failure' },
      });
    }
  } catch (e: any) {
    if (e.message === AUTH_ERROR_NOT_MATCH) {
      dispatch({
        type: USER_LOGIN_FAILURE,
        error: true,
        messages: { message: 'The username and password you entered did not match' || e?.message },
      });
    } else if (e.message === AUTH_ERROR_NOT_FOUND) {
      dispatch({
        type: USER_LOGIN_FAILURE,
        error: true,
        messages: { message: 'User not found' || e?.message },
      });
    } else {
      dispatch({
        type: USER_LOGIN_FAILURE,
        error: true,
        messages: { message: 'Network Failure' },
      });
    }
  }
};

export const userLogout = () => async (dispatch: Dispatch<UserDispatchTypes>) => {
  try {
    dispatch({
      type: USER_LOGOUT,
    });
    const res: any = await logoutService();
    if (res) {
      if (res.code === SUCCESS) {
        clearAll();
        window.location.href = '/login';
        dispatch({
          type: USER_LOGOUT_SUCCESS,
        });
      }
    } else {
      dispatch({
        type: USER_LOGOUT_FAILURE,
      });
    }
  } catch (e: any) {
    dispatch({
      type: USER_LOGOUT_FAILURE,
    });
  }
};

export const GetUserPreference = () => async (dispatch: Dispatch<UserDispatchTypes>) => {
  try {
    dispatch({
      type: GET_USER_INFO,
    });
    const res: any = await getUserInfoService();

    dispatch({
      type: GET_USER_INFO_SUCCESS,
      payload: res.data,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: GET_USER_INFO_FAILURE,
      error: true,
      messages: e.message,
    });
  }
};