export const COLUMNS_CONSUMER = [{
    title: 'ENQUIRY DATE',
    dataIndex: 'inquiry_date',
    active: true,
    width: '110px',
    sorter: () => { },
    fixed: 'left',
    type: 1,
}, {
    title: 'NAME',
    dataIndex: 'name',
    active: true,
    width: '150px',
    sorter: () => { },
    fixed: 'left',
    type: 1,
}, {
    title: 'PHONE NUMBER',
    dataIndex: 'phone',
    active: true,
    width: '160px',
    sorter: () => { },
    fixed: 'left',
    type: 1,
}, {
    title: 'EMAIL',
    dataIndex: 'email',
    active: true,
    width: '150px',
    sorter: () => { },
    type: 1,
}, {
    title: 'PROJECT DEALS',
    dataIndex: 'project_deals',
    active: true,
    width: '150px',
    sorter: () => { },
    type: 1,
}, {
    title: 'LEAD TYPE',
    dataIndex: 'lead_type',
    active: true,
    width: '120px',
    align: 'center',
    sorter: () => { },
    type: 1,
}, {
    title: 'SOURCE',
    dataIndex: 'source',
    active: true,
    width: '150px',
    sorter: () => { },
    type: 1,
}, {
    title: 'ORIGIN PROJECT',
    dataIndex: 'origin_project',
    active: true,
    width: '150px',
    sorter: () => { },
    type: 1,
}, {
    title: 'TELESALES',
    dataIndex: 'telesales',
    active: true,
    width: '150px',
    sorter: () => { },
    type: 1,
}, {
    title: 'LEAD STATUS',
    dataIndex: 'lead_status',
    active: true,
    width: '120px',
    align: 'center',
    sorter: () => { },
    type: 1,
}, {
    title: 'CONTACT STATUS',
    dataIndex: 'contact_status',
    active: true,
    width: '150px',
    sorter: () => { },
    type: 1,
}, {
    title: 'LATEST STAGE',
    dataIndex: 'latest_stage',
    active: true,
    width: '150px',
    sorter: () => { },
    type: 1,
}, {
    title: 'WON STATUS',
    dataIndex: 'won_status',
    active: true,
    width: '150px',
    sorter: () => { },
    type: 1,
}, {
    title: 'CLOSED DATE',
    dataIndex: 'close_date',
    active: true,
    width: '150px',
    sorter: () => { },
    type: 1,
}, {
    title: 'LAST UPDATE',
    dataIndex: 'last_update',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 1,
    hide: true,
}, {
    title: 'LEAD TIME',
    dataIndex: 'lead_time',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 1,
    hide: true,
}, {
    title: 'CHANNEL',
    dataIndex: 'channel',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 1,
}, {
    title: 'NOTES',
    dataIndex: 'notes',
    active: true,
    width: '300px',
    sorter: () => { },
    type: 1,
}, {
    title: 'LAST CONTACT',
    dataIndex: 'last_contact',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 2,
}, {
    title: 'NEXT CONTACT',
    dataIndex: 'next_contact',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 2,
}, {
    title: 'CONTACT METHOD',
    dataIndex: 'contact_method',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 2,
}, {
    title: 'CALL ATTEMPT',
    dataIndex: 'call_attempt',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 2,
}, {
    title: 'LOCATION SUITABLE',
    dataIndex: 'location_suitable',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 2,
}, {
    title: 'PRICE SUITABLE',
    dataIndex: 'price_suitable',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 2,
}, {
    title: 'VISIT STATUS',
    dataIndex: 'visit_status',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 3,
}, {
    title: 'VISIT DATE',
    dataIndex: 'visit_date',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 3,
}, {
    title: 'SITE VISIT CHECK IN',
    dataIndex: 'site_visit_checkin',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 3,
}, {
    title: 'PROOF VISIT',
    dataIndex: 'proof_visit',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 3,
}, {
    title: 'LOCATION CITY',
    dataIndex: 'location_city',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 4,
}, {
    title: 'PRICE',
    dataIndex: 'price',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 4,
}, {
    title: 'RENTAL PRICE',
    dataIndex: 'rental_price',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 4,
}, {
    title: 'TYPE PROPERTY',
    dataIndex: 'type_property',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 4,
}, {
    title: 'INTERESTED PROPERTY',
    dataIndex: 'interested_property',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 4,
    hide: true,
}, {
    title: 'BEDROOM',
    dataIndex: 'bedroom',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 4,
}, {
    title: 'TIMELINE',
    dataIndex: 'timeline',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 4,
}, {
    title: 'PURPOSE',
    dataIndex: 'purpose',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 4,
}, {
    title: 'PAYMENT METHOD',
    dataIndex: 'payment_method',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 4,
}, {
    title: 'AGENT IDENTIFIER',
    dataIndex: 'agent_identifier',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 5,
    hide: true,
}, {
    title: 'DUPLICATE STATUS',
    dataIndex: 'duplicate_status',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 5,
}, {
    title: '',
    dataIndex: 'action',
    active: true,
    width: '50px',
    fixed: 'right',
    type: 1,
}];

export const COLUMNS_CONSUMER_TYPE = [{
    id: 1,
    value: 'Detail Information'
}, {
    id: 2,
    value: 'Follow Up Information'
}, {
    id: 3,
    value: 'Follow Up Result'
}, {
    id: 4,
    value: 'Lead Preference Telesales'
}, {
    id: 5,
    value: 'Hidden Fields'
}]