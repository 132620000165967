import {
  EditIcon
} from 'src/components/icons/icon';
import {
  DeleteOutlined,
  SyncOutlined
} from '@ant-design/icons';

export const IS_LOCAL = 'localhost';
export const SUCCESS = 200;
export const CONFLICT = 409;
export const INPUT_ERROR = 422;
export const PROGRESS = 202;

export const TYPE_ADD = 'add';
export const TYPE_EDIT = 'edit';
export const TYPE_MANAGEMENT = 'management';
export const TYPE_DETAIL = 'detail';
export const TYPE_UPDATE = 'update';
export const TYPE_CLOSE = 'close';
export const CONTACT_TYPE_EMAIL = 1;
export const CONTACT_TYPE_PHONE = 2;
export const TYPE_CHANGE_VALUE_SINGLE = 1;
export const TYPE_CHANGE_VALUE_OBJECT = 2;
export const DEFAULT_PAGE = 10;
export const DEBOUNCE_TIMEOUT = 700;
export const DATE_FORMAT = 'YYYY-MM-DD';
export const HISTORY_ARRAY_MODE_COMMA = true;

export interface PaginationInterface {
  current: number;
  pageSize: number;
  total: number;
}

export const ACTION_TABLE_DROPDOWN = [{
  key: 1,
  label: 'Edit',
  icon: <EditIcon color="#738492" />,
  hide: false
}, {
  key: 2,
  label: 'Delete',
  icon: <DeleteOutlined style={{ fontSize: '16px' }} />,
  hide: true
}];
export const ACTION_CONTACT_TABLE_DROPDOWN = [{
  key: 1,
  label: 'Convert To Leads',
  icon: <SyncOutlined style={{ fontSize: '16px' }} />,
  hide: false
}];
export const ACTION_EDIT = 1;
export const ACTION_DELETE = 2;
export const TYPES_INPUT = {
  COLOR: "color",
  CHECKBOX: "checkbox",
  DATALIST: "datalist",
  DATE: "date",
  DATETIME_LOCAL: "datetime-local",
  EMAIL: "email",
  FILE: "file",
  MONTH: "month",
  NUMBER: "number",
  PASSWORD: "password",
  RADIO: "radio",
  RANGE: "range",
  SELECT: "select",
  SELECT_CUSTOM: "select-custom",
  TEXT: "text",
  TEXTAREA: "textarea",
  TIME: "time",
  WEEK: "week"
}

export const AUTH_ERROR_NOT_FOUND = 'user not found';
export const AUTH_ERROR_NOT_MATCH = 'invalid user password';
export const TYPE_NOTIFICATION_REMINDER = 'reminder';
export const TYPE_NOTIFICATION_IMPORT_LEADS = 'import leads';
export const TYPE_ALERT_SUCCESS = 'success';
export const TYPE_ALERT_ERROR = 'error';

export const TYPE_TELESALES_DEFAULT = 'default';
export const TYPE_TELESALES_DIRECT = 'direct_report';

export const WORKSPACE_LEAD = {
  id: 1,
  name: "Lead",
  url: 'leads',
  class: "default",
  lead_info: true,
  lead_progress: true,
  lead_progress_more: {
    status: true,
    contact_status: true,
    delivery_status: true,
    won_status: false,
    drop_status: false,
    lead_time: true,
    convert_to_leads: false,
    stage: true,
  },
  detail: true,
  detail_more: {
    lead: true,
    telesales: true,
    telesales_follow_up_info: true,
    telesales_follow_up_result: true,
    telesales_lead: true,
  },
  activity: true,
  activity_more: {
    activities: true,
    activities_call: true,
    activities_notes: true,
    activities_task: true,
    activities_history: true,
    data_log: true,
    cross_selling: true
  }
}
export const WORKSPACE_OPPORTUNIES = {
  id: 2,
  name: "Opportunities",
  url: "opportunities",
  class: "success",
}
export const WORKSPACE_CONSUMER = {
  id: 3,
  name: "Consumer",
  url: "consumer",
  class: "success",
  lead_info: true,
  lead_progress: true,
  lead_progress_more: {
    status: true,
    contact_status: true,
    delivery_status: true,
    won_status: true,
    drop_status: false,
    lead_time: false,
    convert_to_leads: false,
    stage: true,
  },
  detail: true,
  detail_more: {
    lead: true,
    telesales: true,
    telesales_follow_up_info: true,
    telesales_follow_up_result: true,
    telesales_lead: true,
  },
  activity: true,
  activity_more: {
    activities: true,
    activities_call: true,
    activities_notes: true,
    activities_task: true,
    activities_history: true,
    data_log: true,
    cross_selling: true
  }
}
export const WORKSPACE_CONTACT = {
  id: 4,
  name: "Contact",
  url: "contact",
  class: "danger",
  lead_info: true,
  lead_progress: true,
  lead_progress_more: {
    status: true,
    contact_status: true,
    delivery_status: true,
    won_status: false,
    drop_status: true,
    lead_time: false,
    convert_to_leads: true,
    stage: true,
  },
  detail: true,
  detail_more: {
    lead: true,
    telesales: true,
    telesales_follow_up_info: true,
    telesales_follow_up_result: true,
    telesales_lead: true,
  },
  activity: true,
  activity_more: {
    activities: true,
    activities_call: true,
    activities_notes: true,
    activities_task: true,
    activities_history: true,
    data_log: true,
    cross_selling: true
  }
}
export const WORKSPACE = [
  WORKSPACE_LEAD,
  WORKSPACE_OPPORTUNIES,
  WORKSPACE_CONSUMER,
  WORKSPACE_CONTACT
]

export const TYPE_PUSHER_REMINDER = 'reminder_notification';
export const TYPE_PUSHER_IMPORT_LEADS = 'bulk_import_lead_finished';
export const TYPE_PUSHER_LEAD_CREATED = "lead_created";
export const TYPE_PUSHER_LEAD_DISTRIBUTED = "lead_distributed";
export const TYPE_PUSHER_LEAD_CROSS_SELLING = "lead_cross_selling";
export const TYPE_PUSHER_BULK_CONVERT_LEAD_FINISHED = "bulk_convert_lead_finished";
export const TYPE_PUSHER_LEAD_ASSIGNMENT = "lead_assignment";
export const TYPE_PUSHER_BULK_LEAD_ASSIGNMENT = "bulk_lead_assignment";
export const TYPE_PUSHER_LEAD_DROPED = "lead_dropped";
export const TYPE_PUSHER_NOTIFICATION = [
  TYPE_PUSHER_REMINDER,
  TYPE_PUSHER_IMPORT_LEADS,
  TYPE_PUSHER_LEAD_ASSIGNMENT,
  TYPE_PUSHER_BULK_LEAD_ASSIGNMENT
];
export const TYPE_PUSHER_TABLE = [
  TYPE_PUSHER_LEAD_CREATED,
  TYPE_PUSHER_LEAD_DISTRIBUTED,
  TYPE_PUSHER_LEAD_CROSS_SELLING,
  TYPE_PUSHER_BULK_CONVERT_LEAD_FINISHED,
  TYPE_PUSHER_IMPORT_LEADS
];

export const TYPE_SEARCH_BY_NAVBAR = 'navbar';
export const TYPE_SEARCH_BY_FILTER = 'filter';
export const NAVBAR_LEADS = {
  index: 'leads',
  title: 'Leads Name',
  value: 'value',
  label: 'label'
}
export const NAVBAR_PROJECT_DEALS = {
  index: 'project_deals',
  title: 'Project Deals',
  value: 'id',
  label: 'name'
}
export const NAVBAR_ORIGIN_PROJECT = {
  index: 'origin_project',
  title: 'Origin Project',
  value: 'id',
  label: 'name'
}
export const NAVBAR_OPTION = [
  NAVBAR_LEADS,
  NAVBAR_PROJECT_DEALS,
  NAVBAR_ORIGIN_PROJECT
]