export const GET_OPPORTUNITIES = 'GET_OPPORTUNITIES';
export const GET_OPPORTUNITIES_SUCCESS = 'GET_OPPORTUNITIES_SUCCESS';
export const GET_OPPORTUNITIES_FAILURE = 'GET_OPPORTUNITIES_FAILURE';

export const GET_OPPORTUNITIES_FILTER = 'GET_OPPORTUNITIES_FILTER';
export const GET_OPPORTUNITIES_SEARCH_SUGGESTION_SUCCESS = 'GET_OPPORTUNITIES_SEARCH_SUGGESTION_SUCCESS';
export const GET_OPPORTUNITIES_SEARCH_SUGGESTION_FAILURE = 'GET_OPPORTUNITIES_SEARCH_SUGGESTION_FAILURE';

export type OpportunitiesType = {
  data: any;
};
export interface GetOpportunities {
  type: typeof GET_OPPORTUNITIES;
}
export interface GetOpportunitiesSuccess {
  type: typeof GET_OPPORTUNITIES_SUCCESS;
  payload: OpportunitiesType;
}
export interface GetOpportunitiesFailure {
  type: typeof GET_OPPORTUNITIES_FAILURE;
  message: string;
}

export interface GetOpportunitiesFilter {
  type: typeof GET_OPPORTUNITIES_FILTER;
  payload: any;
}

export interface GetOpportunitiesSearchSuggestionSuccess {
  type: typeof GET_OPPORTUNITIES_SEARCH_SUGGESTION_SUCCESS;
  payload: OpportunitiesType;
}

export interface GetOpportunitiesSearchSuggestionFailure {
  type: typeof GET_OPPORTUNITIES_SEARCH_SUGGESTION_FAILURE;
}

export type OpportunitiesDispatchTypes =
  | GetOpportunities
  | GetOpportunitiesSuccess
  | GetOpportunitiesFailure
  | GetOpportunitiesFilter
  | GetOpportunitiesSearchSuggestionSuccess
  | GetOpportunitiesSearchSuggestionFailure;
