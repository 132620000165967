import { notification as toast } from 'antd';

import 'src/assets/css/toasts.scss';
import { TYPE_NOTIFICATION_IMPORT_LEADS, TYPE_NOTIFICATION_REMINDER } from 'src/utils/constants/global.constants';

const Toast = props => {
  const { message, alert, mode, icon } = props;
  switch (mode) {
    case TYPE_NOTIFICATION_REMINDER:
      toast.open({
        ...props,
        className: 'reminder',
        message: message,
        icon: icon || undefined,
        duration: 0
      });
      return;
    case TYPE_NOTIFICATION_IMPORT_LEADS:
      toast.open({
        ...props,
        className: 'import-leads',
        message: message,
        icon: icon || undefined,
        duration: 0
      });
      return;
    default:
      toast.open({
        ...props,
        className: alert ? `toast toast-${alert}` : 'toast',
        message: message,
      });
      return;
  }
};

export default Toast;
