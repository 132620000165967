import ApiClient from 'src/utils/api-client'

const getGlobalParamsLeadsService = () =>
    ApiClient.withAuth()
        .then(api => api.get('/lead/params', null, {})
            .then(result => result?.data)
        );
const getGlobalParamsPipelineService = id =>
    ApiClient.withAuth()
        .then(api => api.get(`/lead/pipeline/${id}`, null, null)
            .then(result => result?.data)
        );
const getGlobalParamsProjectsService = () =>
    ApiClient.withAuth()
        .then(api => api.get('/project/options', null, {})
            .then(result => result?.data)
        );
const getGlobalLocationsService = (payload) =>
    ApiClient.withAuth()
        .then(api => api.get(`/user-preference/location/options`, payload, null)
            .then(result => result?.data)
        );

const getFileStorageService = (file, payload) =>
    ApiClient.withAuth()
        .then(api => api.get(`/user-preference/storage/preview/${file}`, payload, {})
            .then(result => result?.data)
        );

const getProjectRefTypeService = () =>
    ApiClient.withAuth()
        .then(api => api.get(`/project/ref-type`, {}, {})
            .then(result => result?.data)
        );

const downloadFileStorageService = (payload) =>
    ApiClient.withAuth()
        .then(api => api.get(`/user-preference/storage/download`, payload, {
            responseType: 'blob',
            ContentType: 'octet-stream ',
        })
            .then(result => result)
        );

export {
    getGlobalParamsLeadsService,
    getGlobalParamsPipelineService,
    getGlobalParamsProjectsService,
    getGlobalLocationsService,
    getFileStorageService,
    getProjectRefTypeService,
    downloadFileStorageService
};