import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from './auth/auth-reducer';
import settingsReducer from './settings/settings-reducer';
import leadsReducer from './leads/leads-reducer';
import globalReducer from './globals/globals-reducer';
import consumersReducer from './consumers/consumers-reducer';
import contactsReducer from './contacts/contacts-reducer';
import opportunitiesReducer from './opportunities/opportunities-reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

const rootReducer = combineReducers({
  auth: authReducer,
  settings: settingsReducer,
  leads: leadsReducer,
  contacts: contactsReducer,
  consumers: consumersReducer,
  globals: globalReducer,
  opportunities: opportunitiesReducer,
});

export default persistReducer(persistConfig, rootReducer);
