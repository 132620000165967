import * as dotenv from 'dotenv';
import { EndpointInterface } from './interface/env.interface';

dotenv.config();

export const endpoint: EndpointInterface = {
  baseUrl: process.env.REACT_APP_CRM_DEVELOPER_BUSINESS_BASE_URL,
  port: process.env.REACT_APP_CRM_DEVELOPER_BUSINESS_PORT_USER_PREFERENCE,
  version: process.env.REACT_APP_CRM_DEVELOPER_BUSINESS_VERSION,
  pusherKey: process.env.REACT_APP_CRM_DEVELOPER_BUSINESS_PUSHER_KEY,
  pusherCluster: process.env.REACT_APP_CRM_DEVELOPER_BUSINESS_PUSHER_CLUSTER,
  pusherChannel: process.env.REACT_APP_CRM_DEVELOPER_BUSINESS_PUSHER_CHANNEL,
  pusherEnableLogger: process.env.REACT_APP_CRM_DEVELOPER_BUSINESS_PUSHER_ENABLE_LOGGER,
};