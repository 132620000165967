export const GET_CONTACTS = 'GET_CONTACTS';
export const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS';
export const GET_CONTACTS_FAILURE = 'GET_CONTACTS_FAILURE';
export const GET_CONTACTS_FILTER = 'GET_CONTACTS_FILTER';
export const GET_CONTACTS_SEARCH_SUGGESTION_SUCCESS = 'GET_CONTACTS_SEARCH_SUGGESTION_SUCCESS';
export const GET_CONTACTS_SEARCH_SUGGESTION_FAILURE = 'GET_CONTACTS_SEARCH_SUGGESTION_FAILURE';
export type ContactsType = {
  data: any;
};
export interface GetContacts {
  type: typeof GET_CONTACTS;
}
export interface GetContactsSuccess {
  type: typeof GET_CONTACTS_SUCCESS;
  payload: ContactsType;
}
export interface GetContactsFailure {
  type: typeof GET_CONTACTS_FAILURE;
  message: string;
}

export interface GetContactsFilter {
  type: typeof GET_CONTACTS_FILTER;
  payload: any;
}

export interface GetContactSearchSuggestionSuccess {
  type: typeof GET_CONTACTS_SEARCH_SUGGESTION_SUCCESS;
  payload: ContactsType;
}

export interface GetContactSearchSuggestionFailure {
  type: typeof GET_CONTACTS_SEARCH_SUGGESTION_FAILURE;
}

export type ContactsDispatchTypes =
  | GetContacts
  | GetContactsSuccess
  | GetContactsFailure
  | GetContactsFilter
  | GetContactSearchSuggestionSuccess
  | GetContactSearchSuggestionFailure;
