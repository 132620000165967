export const ADD_LEADS_DROPDOWN = [
  {
    key: 1,
    label: 'Add Leads',
    active: true,
  },
  {
    key: 2,
    label: 'Import Leads',
    active: true,
  },
];

export const TYPE_ADD_LEADS = 1;
export const TYPE_ADD_LEADS_IMPORT = 2;
export const TYPE_UPDATE_LEAD = 1;
export const TYPE_UPDATE_LEAD_TELESALES_FOLLOW_UP = 2;
export const TYPE_UPDATE_LEAD_TELESALES_FOLLOW_RESULT = 3;
export const TYPE_UPDATE_LEAD_TELESALES_FOLLOW_PREFERENCE = 4;
export const TYPE_UPDATE_LEAD_STATUS = 5;
export const TYPE_UPDATE_LEAD_STATUS_CONTACT = 6;
export const TYPE_UPDATE_LEAD_STAGE = 7;
export const TYPE_UPDATE_LEAD_STAGE_CLOSE_WON = 8;
export const TYPE_UPDATE_LEAD_STAGE_CLOSE_DROP = 9;
export const TYPE_UPDATE_LEAD_ASSIGN = 10;
export const TYPE_UPDATE_LEAD_STAGE_DISTRIBUTED = 11;
export const TYPE_UPDATE_LEAD_STATUS_CLOSE = 12;
export const TYPE_UPDATE_LEAD_CROSS_SELLING = 13;
export const TYPE_UPDATE_LEAD_METHOD = 14;

export const COLUMNS_LEAD_TYPE = [
  {
    id: 1,
    value: 'Detail Information',
  },
  {
    id: 2,
    value: 'Follow Up Information',
  },
  {
    id: 3,
    value: 'Follow Up Result',
  },
  {
    id: 4,
    value: 'Lead Preference Telesales',
  },
  {
    id: 5,
    value: 'Hidden Fields',
  },
];

export const COLUMNS_LEAD = [
  {
    title: 'ENQUIRY DATE',
    dataIndex: 'inquiry_date',
    active: true,
    width: '110px',
    sorter: () => { },
    fixed: 'left',
    type: 1,
  },
  {
    title: 'NAME',
    dataIndex: 'name',
    active: true,
    width: '150px',
    sorter: () => { },
    fixed: 'left',
    type: 1,
  },
  {
    title: 'PHONE NUMBER',
    dataIndex: 'phone',
    active: true,
    width: '160px',
    sorter: () => { },
    fixed: 'left',
    type: 1,
  },
  {
    title: 'EMAIL',
    dataIndex: 'email',
    active: true,
    width: '150px',
    sorter: () => { },
    type: 1,
  },
  {
    title: 'PROJECT DEALS',
    dataIndex: 'project_deals',
    active: true,
    width: '150px',
    sorter: () => { },
    type: 1,
  },
  {
    title: 'LEAD TYPE',
    dataIndex: 'lead_type',
    active: true,
    width: '120px',
    align: 'center',
    sorter: () => { },
    type: 1,
  },
  {
    title: 'SOURCE',
    dataIndex: 'source',
    active: true,
    width: '150px',
    sorter: () => { },
    type: 1,
  },
  {
    title: 'TELESALES',
    dataIndex: 'telesales',
    active: true,
    width: '150px',
    sorter: () => { },
    type: 1,
  },
  {
    title: 'LEAD STATUS',
    dataIndex: 'lead_status',
    active: true,
    width: '120px',
    align: 'center',
    sorter: () => { },
    type: 1,
  },
  {
    title: 'CONTACT STATUS',
    dataIndex: 'contact_status',
    active: true,
    width: '150px',
    sorter: () => { },
    type: 1,
  },
  {
    title: 'LATEST STAGE',
    dataIndex: 'latest_stage',
    active: true,
    width: '150px',
    sorter: () => { },
    type: 1,
  },
  {
    title: 'LAST UPDATE',
    dataIndex: 'last_update',
    active: true,
    width: '150px',
    sorter: () => { },
    type: 1,
  },
  {
    title: 'LEAD TIME',
    dataIndex: 'lead_time',
    active: true,
    width: '150px',
    sorter: () => { },
    type: 1,
  },
  {
    title: 'ORIGIN PROJECT',
    dataIndex: 'origin_project',
    active: true,
    width: '150px',
    sorter: () => { },
    type: 1,
  },
  {
    title: 'CHANNEL',
    dataIndex: 'channel',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 1,
  },
  {
    title: 'NOTES',
    dataIndex: 'notes',
    active: true,
    width: '300px',
    sorter: () => { },
    type: 1,
  },
  {
    title: 'LAST CONTACT',
    dataIndex: 'last_contact',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 2,
  },
  {
    title: 'NEXT CONTACT',
    dataIndex: 'next_contact',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 2,
  },
  {
    title: 'CONTACT METHOD',
    dataIndex: 'contact_method',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 2,
  },
  {
    title: 'CALL ATTEMPT',
    dataIndex: 'call_attempt',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 2,
  },
  {
    title: 'LOCATION SUITABLE',
    dataIndex: 'location_suitable',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 2,
  },
  {
    title: 'PRICE SUITABLE',
    dataIndex: 'price_suitable',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 2,
  },
  {
    title: 'VISIT STATUS',
    dataIndex: 'visit_status',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 3,
  },
  {
    title: 'VISIT DATE',
    dataIndex: 'visit_date',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 3,
  },
  {
    title: 'SITE VISIT CHECK IN',
    dataIndex: 'site_visit_checkin',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 3,
  },
  {
    title: 'PROOF VISIT',
    dataIndex: 'proof_visit',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 3,
  },
  {
    title: 'LOCATION CITY',
    dataIndex: 'location_city',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 4,
  },
  {
    title: 'PRICE',
    dataIndex: 'price',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 4,
  },
  {
    title: 'RENTAL PRICE',
    dataIndex: 'rental_price',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 4,
  },
  {
    title: 'TYPE PROPERTY',
    dataIndex: 'type_property',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 4,
  },
  {
    title: 'INTERESTED PROPERTY',
    dataIndex: 'interested_property',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 4,
    hide: true,
  },
  {
    title: 'BEDROOM',
    dataIndex: 'bedroom',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 4,
  },
  {
    title: 'TIMELINE',
    dataIndex: 'timeline',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 4,
  },
  {
    title: 'PURPOSE',
    dataIndex: 'purpose',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 4,
  },
  {
    title: 'PAYMENT METHOD',
    dataIndex: 'payment_method',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 4,
  },
  {
    title: 'AGENT IDENTIFIER',
    dataIndex: 'agent_identifier',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 5,
    hide: true,
  },
  {
    title: 'DUPLICATE STATUS',
    dataIndex: 'duplicate_status',
    active: false,
    width: '150px',
    sorter: () => { },
    type: 5,
  },
  {
    title: '',
    dataIndex: 'action',
    active: true,
    width: '50px',
    fixed: 'right',
    type: 1,
  },
];

export const STAGE_FLOW_NEW = {
  id: 1,
  stage_group_id: 1,
  order: 1,
  stage_id: 1,
  stage_name: 'New Lead',
  active: true,
};
export const STAGE_FLOW_FOLLOW_UP = {
  id: 2,
  stage_group_id: 2,
  order: 2,
  stage_id: 2,
  stage_name: 'Follow Up',
  active: false,
};
export const STAGE_FLOW_CONTACTED = {
  id: 3,
  stage_group_id: 3,
  order: 3,
  stage_id: 3,
  stage_name: 'Contacted',
  active: false,
};
export const STAGE_FLOW_QUALIFICATION = {
  id: 4,
  stage_group_id: 4,
  order: 4,
  stage_id: 4,
  stage_name: 'Qualified',
  active: false,
};
export const STAGE_FLOW_DISTRIBUTED = {
  id: 5,
  stage_group_id: 5,
  order: 5,
  stage_id: 5,
  stage_name: 'Distributed',
  active: false,
};

export const STAGE_FLOW = [STAGE_FLOW_NEW, STAGE_FLOW_FOLLOW_UP, STAGE_FLOW_CONTACTED, STAGE_FLOW_QUALIFICATION, STAGE_FLOW_DISTRIBUTED];

export const STATUS_NEW = {
  id: 1,
  name: 'New',
};
export const STATUS_HOT = {
  id: 2,
  name: 'Hot',
};
export const STATUS_WARM = {
  id: 3,
  name: 'Warm',
};
export const STATUS_COLD = {
  id: 4,
  name: 'Cold',
};

export const STATUS_LEAD = [STATUS_NEW, STATUS_HOT, STATUS_WARM, STATUS_COLD];

export const LEAD_ACTION_DISTRIBUTED = {
  id: 1,
  name: 'Distributed',
  type: 'text-dark',
  value: 'Distributed',
  process: 'default',
};
export const LEAD_ACTION_DISTRIBUTED_WON = {
  id: 2,
  name: 'Won',
  type: 'text-success',
  value: 'Closed Won',
  process: 'success',
};
export const LEAD_ACTION_DISTRIBUTED_DROP = {
  id: 3,
  name: 'Drop',
  type: 'text-danger',
  value: 'Closed Drop',
  process: 'danger',
};
export const LEAD_ACTION_CROSS_SELLING = {
  id: 4,
  name: 'Cross Selling',
  title: 'Cross selling to',
};

export const LEAD_DISTRIBUTED_DROPDOWN = [LEAD_ACTION_DISTRIBUTED, LEAD_ACTION_DISTRIBUTED_WON, LEAD_ACTION_DISTRIBUTED_DROP];

export const TYPE_DISTRIBUTED = 1;
export const TYPE_DISTRIBUTED_WON = 2;
export const TYPE_DISTRIBUTED_DROP = 3;

export const ACTIVITIES_CATEGORY = [
  {
    id: 1,
    value: 'Log Call',
  },
  {
    id: 2,
    value: 'Notes',
  },
  {
    id: 3,
    value: 'Task',
  },
];

export const ACTIVITIES_TYPE = [
  {
    id: 1,
    value: 'created',
  },
  {
    id: 2,
    value: 'updated',
  },
];

export const GLOBAL_PARAMS_LEAD_STATUS = 'lead_status';
export const GLOBAL_PARAMS_LEAD_CHANNEL = 'lead_channel';
export const GLOBAL_PARAMS_CONTACT_STATUS = 'contact_status';
export const GLOBAL_PARAMS_VISIT_STATUS = 'visit_status';
export const GLOBAL_PARAMS_CONTACT_METHOD = 'contact_method';
export const GLOBAL_PARAMS_PRICE = 'price';
export const GLOBAL_PARAMS_RENTAL_PRICE = 'rental_price';
export const GLOBAL_PARAMS_TYPE_PROPERTY = 'type_property';
export const GLOBAL_PARAMS_TIME_LINE = 'timeline';
export const GLOBAL_PARAMS_PURPOSE = 'purpose';
export const GLOBAL_PARAMS_PAYMENT_METHOD = 'payment_method';
export const GLOBAL_PARAMS_BEDROOM = 'bedroom';
export const GLOBAL_PARAMS_STATUS_WON = 'won_status';
export const GLOBAL_PARAMS_STATUS_DROP = 'drop_status';
export const GLOBAL_PARAMS_LEAD_METHOD = 'lead_method';
export const GLOBAL_PARAMS_CONFIRMATION = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const LEADS_FILTER_OPTION_ENQUIRY_TIME = 'lead_enquiry_time';
export const LEADS_FILTER_OPTION_LEAD_TYPES = 'lead_types';
export const LEADS_FILTER_OPTION_LEAD_SOURCES = 'lead_sources';
export const LEADS_FILTER_OPTION_LEAD_STATUS = 'lead_status';
export const LEADS_FILTER_OPTION_CONTACT_STATUS = 'contact_status';
export const LEADS_FILTER_OPTION_LEAD_STAGES = 'lead_stages';
export const LEADS_FILTER_OPTION_LEAD_SORTING_TIME = 'lead_sorting_time';
export const LEADS_FILTER_OPTION_LEAD_UPDATE_TIME = 'lead_update_time';

export const LEAD_DUPLICATE = 'Duplicate';
export const LEAD_DUPLICATE_ORIGINAL = 'Original';

export const DROP_STATUS_OVERBUDGET = 6;
export const DROP_STATUS_LOST_TO_COMPETITOR = 7;
export const DROP_STATUS_ALREADY_PURCHASED_OTHER_PROPERTY = 8;
export const LEADS_FILTER_OPTION_DISTRIBUTED_TIME = 'lead_distributed_time';

export const TAB_DETAIL_LEAD = 'Details';
export const TAB_DETAIL_TELESALES = 'Telesales';

export const CHANGE_STATUS_WON = 'change_status_won';
export const CHANGE_STATUS_DROP = 'change_status_drop';
export const CHANGE_STATUS_DROP_CONTACTED = 'change_status_drop_contacted';

export const TYPE_CONVERT_SINGLE = 'single';
export const TYPE_CONVERT_BULK = 'bulk';

export const LEAD_AGENT = 'Agent Secondary';

export const LEAD_MANUAL = 1;
export const LEAD_SYNC = 0;