import { Tag } from 'antd';
import 'src/assets/css/badge.scss';

const Badge = ({ data, labelFunction = str => str.toUpperCase() }: any) => {
  if (typeof data === 'string') {
    const label = labelFunction(data);
    switch (data.toUpperCase()) {
      case 'HOT':
        return <Tag className='badge badge-hot'>{label}</Tag>;
      case 'NEW':
        return <Tag className='badge badge-new'>{label}</Tag>;
      case 'WARM':
        return <Tag className='badge badge-warm'>{label}</Tag>;
      case 'COLD':
        return <Tag className='badge badge-cold'>{label}</Tag>;
      case 'WON':
        return <Tag className='badge badge-won'>{label}</Tag>;
      case 'LGP':
        return <Tag className='badge badge-lead-type'>{label}</Tag>;
      case 'LGP+':
        return <Tag className='badge badge-lead-type'>{label}</Tag>;
      case 'PASTI':
        return <Tag className='badge badge-lead-type'>{label}</Tag>;
      case 'COMPLETED':
        return <Tag className='badge badge-completed'>{data}</Tag>;
      case 'AGENT SECONDARY':
        return <Tag className='badge badge-agent'>{label}</Tag>;
      case 'ORIGIN':
        return <Tag className='badge badge-cross-sell badge-cross-sell-origin'>{data}</Tag>;
      case 'MANUAL':
        return <Tag className='badge badge-cross-sell badge-cross-sell-manual'>{data}</Tag>;
      case 'AUTOMATION':
        return <Tag className='badge badge-cross-sell badge-cross-sell-automation'>{data}</Tag>;
      default:
        return <Tag className='badge badge-default'>{label}</Tag>;
    }
  } else {
    return <Tag className='badge badge-unread'>{data} New</Tag>;
  }
};

export default Badge;
