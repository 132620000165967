export const GET_GLOBAL_PARAMS_LEADS = 'GET_GLOBAL_PARAMS_LEADS';
export const GET_GLOBAL_PARAMS_LEADS_SUCCESS = 'GET_GLOBAL_PARAMS_LEADS_SUCCESS';
export const GET_GLOBAL_PARAMS_LEADS_FAILURE = 'GET_GLOBAL_PARAMS_LEADS_FAILURE';
export const GET_GLOBAL_PARAMS_PIPELINE = 'GET_GLOBAL_PARAMS_PIPELINE';
export const GET_GLOBAL_PARAMS_PIPELINE_SUCCESS = 'GET_GLOBAL_PARAMS_PIPELINE_SUCCESS';
export const GET_GLOBAL_PARAMS_PIPELINE_FAILURE = 'GET_GLOBAL_PARAMS_PIPELINE_FAILURE';
export const GET_GLOBAL_PARAMS_PROJECTS = 'GET_GLOBAL_PARAMS_PROJECTS';
export const GET_GLOBAL_PARAMS_PROJECTS_SUCCESS = 'GET_GLOBAL_PARAMS_PROJECTS_SUCCESS';
export const GET_GLOBAL_PARAMS_PROJECTS_FAILURE = 'GET_GLOBAL_PARAMS_PROJECTS_FAILURE';
export const GET_GLOBAL_PARAMS_LOCATIONS = 'GET_GLOBAL_PARAMS_LOCATIONS';
export const GET_GLOBAL_PARAMS_LOCATIONS_SUCCESS = 'GET_GLOBAL_PARAMS_LOCATIONS_SUCCESS';
export const GET_GLOBAL_PARAMS_LOCATIONS_FAILURE = 'GET_GLOBAL_PARAMS_LOCATIONS_FAILURE';

export type GlobalParams = {
  data: any;
};
export interface GetLeadsGlobalParams {
  type: typeof GET_GLOBAL_PARAMS_LEADS;
}
export interface GetLeadsGlobalParamsSuccess {
  type: typeof GET_GLOBAL_PARAMS_LEADS_SUCCESS;
  payload: GlobalParams;
}
export interface GetLeadsGlobalParamsFailure {
  type: typeof GET_GLOBAL_PARAMS_LEADS_FAILURE;
  message: string;
}
export interface GetPipelineGlobalParams {
  type: typeof GET_GLOBAL_PARAMS_PIPELINE;
}
export interface GetPipelineGlobalParamsSuccess {
  type: typeof GET_GLOBAL_PARAMS_PIPELINE_SUCCESS;
  payload: GlobalParams;
}
export interface GetPipelineGlobalParamsFailure {
  type: typeof GET_GLOBAL_PARAMS_PIPELINE_FAILURE;
  message: string;
}
export interface GetProjectsGlobalParams {
  type: typeof GET_GLOBAL_PARAMS_PROJECTS;
}
export interface GetProjectsGlobalParamsSuccess {
  type: typeof GET_GLOBAL_PARAMS_PROJECTS_SUCCESS;
  payload: GlobalParams;
}
export interface GetProjectsGlobalParamsFailure {
  type: typeof GET_GLOBAL_PARAMS_PROJECTS_FAILURE;
  message: string;
}
export interface GetLocationsGlobalParams {
  type: typeof GET_GLOBAL_PARAMS_LOCATIONS;
}
export interface GetLocationsGlobalParamsSuccess {
  type: typeof GET_GLOBAL_PARAMS_LOCATIONS_SUCCESS;
  payload: GlobalParams;
}
export interface GetLocationsGlobalParamsFailure {
  type: typeof GET_GLOBAL_PARAMS_LOCATIONS_FAILURE;
  message: string;
}

export type GlobalsDispatchTypes =
  | GetLeadsGlobalParams
  | GetLeadsGlobalParamsSuccess
  | GetLeadsGlobalParamsFailure
  | GetPipelineGlobalParams
  | GetPipelineGlobalParamsSuccess
  | GetPipelineGlobalParamsFailure
  | GetProjectsGlobalParams
  | GetProjectsGlobalParamsSuccess
  | GetProjectsGlobalParamsFailure
  | GetLocationsGlobalParams
  | GetLocationsGlobalParamsSuccess
  | GetLocationsGlobalParamsFailure;
