export const SETTINGS_USER_TAB = [
  {
    id: '1',
    label: 'Internal User',
  },
  {
    id: '2',
    label: 'External User',
  },
];

export const SETTINGS_USER_TAB_INTERNAL = '1';
export const SETTINGS_USER_TAB_INTERNAL_LABEL = 'Internal User';
export const SETTINGS_USER_TAB_EXTERNAL = '2';
export const SETTINGS_USER_TAB_EXTERNAL_LABEL = 'External User';

export const COLUMNS_USERS_INTERNAL = [
  {
    key: '0',
    title: 'NAME',
    dataIndex: 'name',
    active: true,
    width: '150px',
    sorter: () => {},
  },
  {
    key: '1',
    title: 'EMAIL',
    dataIndex: 'email',
    active: true,
    width: '150px',
    sorter: () => {},
  },
  {
    key: '2',
    title: 'ROLES',
    dataIndex: 'roles',
    active: true,
    width: '150px',
    sorter: () => {},
  },
  {
    key: '3',
    title: 'PROJECT TYPE',
    dataIndex: 'project_type',
    active: true,
    width: '280px',
    sorter: () => {},
  },
  {
    key: '5',
    title: 'CREATED BY',
    dataIndex: 'created_by',
    active: true,
    width: '86px',
    sorter: () => {},
  },
  {
    key: '6',
    title: '',
    dataIndex: 'action',
    active: true,
    width: '50px',
  },
];

export const COLUMNS_USERS_EXTERNAL = [
  {
    key: '0',
    title: 'NAME',
    dataIndex: 'name',
    active: true,
    width: '150px',
    sorter: () => {},
  },
  {
    key: '1',
    title: 'EMAIL',
    dataIndex: 'email',
    active: true,
    width: '150px',
    sorter: () => {},
  },
  {
    key: '2',
    title: 'ROLES',
    dataIndex: 'roles',
    active: true,
    width: '150px',
    sorter: () => {},
  },
  {
    key: '3',
    title: 'DEVELOPER',
    dataIndex: 'developer',
    active: true,
    width: '150px',
    sorter: () => {},
  },
  {
    key: '4',
    title: 'PROJECT',
    dataIndex: 'project',
    active: true,
    width: '150px',
    sorter: () => {},
  },
  {
    key: '5',
    title: 'STATUS',
    dataIndex: 'status',
    active: true,
    width: '80px',
    sorter: () => {},
  },
  {
    key: '6',
    title: 'CREATED BY',
    dataIndex: 'created_by',
    active: true,
    width: '86px',
    sorter: () => {},
  },
  {
    key: '7',
    title: '',
    dataIndex: 'action',
    active: true,
    width: '50px',
  },
];

export const COLUMNS_USERS_ROLES = [
  {
    key: '0',
    title: 'ROLES',
    dataIndex: 'roles',
    active: true,
    width: '500px',
    sorter: () => {},
  },
  {
    key: '1',
    title: 'CREATED DATE',
    dataIndex: 'created_at',
    active: true,
    width: '150px',
    sorter: () => {},
  },
  {
    key: '2',
    title: 'CREATED BY',
    dataIndex: 'created_by',
    active: true,
    width: '150px',
    sorter: () => {},
  },
  {
    key: '3',
    title: '',
    dataIndex: 'action',
    active: true,
    width: '50px',
  },
];

export const COLUMNS_USERS_PERMISSIONS = [
  {
    key: '0',
    title: 'NAME',
    dataIndex: 'name',
    active: true,
    width: '200px',
    sorter: () => {},
  },
  {
    key: '1',
    title: 'PERMISSIONS',
    dataIndex: 'route_name',
    active: true,
    width: '150px',
    sorter: () => {},
  },
  {
    key: '2',
    title: 'MODULE',
    dataIndex: 'module',
    active: true,
    width: '150px',
    sorter: () => {},
  },
  {
    key: '3',
    title: 'CREATED DATE',
    dataIndex: 'created_at',
    active: true,
    width: '120px',
    sorter: () => {},
  },
  {
    key: '4',
    title: 'CREATED BY',
    dataIndex: 'created_by',
    active: true,
    width: '120px',
    sorter: () => {},
  },
  {
    key: '5',
    title: '',
    dataIndex: 'action',
    active: true,
    width: '50px',
  },
];


export const SELECTED_SINGLE = 'one';
export const SELECTED_ALL = 'all';