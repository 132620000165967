import React from "react";

const PusherContext = React.createContext<any | null>(null);

export function PusherProvider({ pusher, children }) {
    return (
        <PusherContext.Provider value={{ pusher }}>
            {children}
        </PusherContext.Provider>
    );
}
export function usePusher() {
    const context = React.useContext(PusherContext);
    if (!context) {
        throw new Error("usePusher must be used within a PusherProvider");
    }

    const { pusher } = context;
    return pusher;
}