import {
    TYPE_ALERT_SUCCESS,
    TYPE_NOTIFICATION_IMPORT_LEADS,
    TYPE_NOTIFICATION_REMINDER,
    TYPE_PUSHER_REMINDER,
    TYPE_PUSHER_LEAD_ASSIGNMENT,
    WORKSPACE,
    TYPE_PUSHER_BULK_LEAD_ASSIGNMENT,
    TYPE_PUSHER_IMPORT_LEADS
} from "src/utils/constants/global.constants";
import {
    NOTIFICATION_IMPORT_LEADS_SUCCEED,
} from "src/utils/constants/notifications.constant";
import {
    BulkFailedIcon,
    BulkSuccessIcon,
    EventIcon,
    SwitchUserIcon,
} from "../icons/icon";
import toast from 'src/components/toast/toast';
import { NotificationPusherInterface } from "./notification.interface";
import { downloadFileStorageService } from "src/redux/globals/globals-service";


const NotificationPusher: React.FC<NotificationPusherInterface> = ({
    data,
    type
}: NotificationPusherInterface) => {
    const getFailedFile = async (failed_path) => {
        const filename = failed_path.split('/');
        const res: any = await downloadFileStorageService({
            filepath: failed_path
        });
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename[filename.length - 1]);

        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    const bucket = data?.workspace_id ? WORKSPACE.find(w => w.id === data?.workspace_id) : undefined;

    switch (type) {
        case TYPE_PUSHER_REMINDER:
            return (
                <>
                    {
                        toast({
                            alert: TYPE_ALERT_SUCCESS,
                            message: data?.subject,
                            mode: TYPE_NOTIFICATION_REMINDER,
                            icon: <EventIcon size={20} color="#3A6ACD" />,
                            description: (
                                <>
                                    <span className="type">{data?.reminder_name}</span> due in 10 mins for
                                    <a href={`/${bucket?.url}?id=${data?.lead_uuid}`}><b>{` ${data?.lead_name}`}</b></a>
                                </>
                            )
                        })
                    }
                </>
            )
        case TYPE_PUSHER_IMPORT_LEADS:
            return (
                <>
                    {
                        toast({
                            alert: TYPE_ALERT_SUCCESS,
                            message: (
                                <>
                                    {data?.subject}
                                    {data?.status === NOTIFICATION_IMPORT_LEADS_SUCCEED ? ` - Success` : ` - Failed`}
                                </>
                            ),
                            mode: TYPE_NOTIFICATION_IMPORT_LEADS,
                            icon: (
                                <>
                                    {data?.status === NOTIFICATION_IMPORT_LEADS_SUCCEED ? <BulkSuccessIcon /> : <BulkFailedIcon />}
                                </>
                            ),
                            description: (
                                <>
                                    <span className="filename">{data?.file_name}</span>
                                    {data?.status === NOTIFICATION_IMPORT_LEADS_SUCCEED ? ` successfully imported,
                                    thank you` : (
                                        <>
                                            <span>{` contain wrong data, see the `}</span>
                                            <span className="name" onClick={() => { getFailedFile(data?.failed_file_path) }}>Report</span>
                                        </>
                                    )}
                                </>
                            )
                        })
                    }
                </>
            )
        case TYPE_PUSHER_LEAD_ASSIGNMENT:
            return (
                <>
                    {
                        toast({
                            alert: TYPE_ALERT_SUCCESS,
                            message: data?.subject,
                            mode: TYPE_NOTIFICATION_REMINDER,
                            icon: <SwitchUserIcon size={20} color="#3A6ACD" />,
                            description: (
                                <>
                                    {data?.sender_name} has been assign <a href={`/${bucket?.url}?id=${data?.lead_uuid}`}><b>{` ${data?.lead_name}`}</b></a> to you
                                </>
                            )
                        })
                    }
                </>
            )
        case TYPE_PUSHER_BULK_LEAD_ASSIGNMENT:
            return (
                <>
                    {
                        toast({
                            alert: TYPE_ALERT_SUCCESS,
                            message: data?.subject,
                            mode: TYPE_NOTIFICATION_REMINDER,
                            icon: <SwitchUserIcon size={20} color="#3A6ACD" />,
                            description: (
                                <>
                                    {data?.sender_name} has been assign {data?.total_lead} leads to you
                                </>
                            )
                        })
                    }
                </>
            )
        default:
            return (<></>)
    }
}
export default NotificationPusher;