import React from 'react';
import './assets/css/ant.less';
import { Switch, Route, Redirect } from 'react-router-dom';
import LoadingBar from './components/loading/loading';
import { isLoggedIn } from 'src/utils/storage';
import { BrowserRouter } from 'react-router-dom';
import { configurePersistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import LazyDelay from './utils/lazy-delay';
import { Provider } from 'react-redux';
import App from './views/App';
import { checkingVersionapp } from './utils/utils';
import { PusherProvider } from './utils/pusher';
import Pusher from "pusher-js";
import { endpoint } from 'src/config/env';

const Login = LazyDelay(import('src/views/authentications/signin'));

Pusher.logToConsole = false;
var pusher = new Pusher(`${endpoint.pusherKey}`, {
  cluster: `${endpoint.pusherCluster}`
});

function BaseApp({ store }) {
  checkingVersionapp();
  const persistor = configurePersistor(store);
  return (
    <PusherProvider pusher={pusher}>
      <Provider store={store}>
        <BrowserRouter>
          <PersistGate persistor={persistor}>
            <React.Suspense fallback={<LoadingBar loading />}>
              <Switch>
                <Route exact path='/' render={() => isLoggedIn() ? <Redirect to='/email' /> : <Redirect to='/login' />} />
                <Route path='/login' component={Login} />
                <App fallback={<LoadingBar loading />} />
              </Switch>
            </React.Suspense>
          </PersistGate>
        </BrowserRouter>
      </Provider>
    </PusherProvider>
  );
}

export default BaseApp;
