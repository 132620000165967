import {
  CONVERT_TO_LEAD,
  CONVERT_TO_LEAD_FAILURE,
  CONVERT_TO_LEAD_SUCCESS,
  CREATE_ACTIVITY,
  CREATE_ACTIVITY_FAILURE,
  CREATE_ACTIVITY_SUCCESS,
  CREATE_LEAD,
  CREATE_LEAD_FAILURE,
  CREATE_LEAD_SUCCESS,
  GET_ACTIVITIES,
  GET_ACTIVITIES_FAILURE,
  GET_ACTIVITIES_SUCCESS,
  GET_DUPLICATE,
  GET_DUPLICATE_FAILURE,
  GET_DUPLICATE_SUCCESS,
  GET_LEAD,
  GET_LEADS,
  GET_LEADS_FAILURE,
  GET_LEADS_SUCCESS,
  GET_LEADS_SUGGESTION_FAILURE,
  GET_LEADS_SUGGESTION_SUCCESS,
  GET_LEAD_FAILURE,
  GET_LEAD_SUCCESS,
  GET_LOG,
  GET_LOG_FAILURE,
  GET_LOG_SUCCESS,
  IMPORT_LEADS,
  IMPORT_LEADS_FAILURE,
  IMPORT_LEADS_SUCCESS,
  LeadsDispatchTypes,
  LeadsType,
  LEAD_ASSIGN,
  LEAD_ASSIGN_FAILURE,
  LEAD_ASSIGN_SUCCESS,
  UPDATE_ACTIVITY,
  UPDATE_ACTIVITY_FAILURE,
  UPDATE_ACTIVITY_SUCCESS,
  UPDATE_FILTER_LEADS,
  UPDATE_LEAD,
  UPDATE_LEAD_FAILURE,
  UPDATE_LEAD_SUCCESS
} from "./leads-types";

interface DefaultStateI {
  loading: boolean;
  error?: boolean;
  messages?: any;

  leads: LeadsType;

  loadingGetLead: boolean;
  successGetLead: boolean;
  errorGetLead: boolean;
  lead?: any;
  errorMessage: {},

  loadingUpdateLead: boolean;
  successUpdateLead: boolean;
  errorUpdateLead: boolean;

  filterQuery: any;

  loadingGetDuplicate: boolean;
  successGetDuplicate: boolean;
  errorGetDuplicate: boolean;
  duplicate?: any;
  errorMessageDuplicate: {},

  loadingGetActivities: boolean;
  successGetActivities: boolean;
  errorGetActivities: boolean;
  activities?: any;
  errorMessageActivities: {},

  loadingCreateActivity: boolean;
  successCreateActivity: boolean;
  errorCreateActivity: boolean;

  loadingUpdateActivity: boolean;
  successUpdateActivity: boolean;
  errorUpdateActivity: boolean;

  loadingCreateLead: boolean;
  successCreateLead: boolean;
  errorCreateLead: boolean;

  leadsSuggestions?: any;

  loadingGetLogs: boolean;
  successGetLogs: boolean;
  errorGetLogs: boolean;
  logs?: any;
  errorMessageLog: {},

  loadingConvertToLead: boolean;
  successConvertToLead: boolean;
  errorConvertToLead: boolean;

  loadingLeadAssigns: boolean;
  successLeadAssigns: boolean;
  errorLeadAssigns: boolean;

  loadingImportLeads: boolean;
  successImportLeads: boolean;
  errorImportLeads: boolean;
}

const defaultState: DefaultStateI = {
  loading: false,
  leads: {
    data: [],
  },

  loadingGetLead: false,
  successGetLead: false,
  errorGetLead: false,
  lead: {},
  errorMessage: {},

  loadingUpdateLead: false,
  successUpdateLead: false,
  errorUpdateLead: false,

  filterQuery: {},

  loadingGetDuplicate: false,
  successGetDuplicate: false,
  errorGetDuplicate: false,
  duplicate: {},
  errorMessageDuplicate: {},

  loadingGetActivities: false,
  successGetActivities: false,
  errorGetActivities: false,
  activities: {},
  errorMessageActivities: {},

  loadingCreateActivity: false,
  successCreateActivity: false,
  errorCreateActivity: false,

  loadingUpdateActivity: false,
  successUpdateActivity: false,
  errorUpdateActivity: false,

  loadingCreateLead: false,
  successCreateLead: false,
  errorCreateLead: false,

  loadingGetLogs: false,
  successGetLogs: false,
  errorGetLogs: false,
  logs: {},
  errorMessageLog: {},

  loadingConvertToLead: false,
  successConvertToLead: false,
  errorConvertToLead: false,

  loadingLeadAssigns: false,
  successLeadAssigns: false,
  errorLeadAssigns: false,

  loadingImportLeads: false,
  successImportLeads: false,
  errorImportLeads: false,
};

const leadsReducer = (state: DefaultStateI = defaultState, action: LeadsDispatchTypes): DefaultStateI => {
  switch (action.type) {
    case GET_LEADS:
      return {
        ...state,
        loading: true,
      };
    case GET_LEADS_SUCCESS:
      return {
        ...state,
        loading: false,
        leads: action.payload,
      };
    case GET_LEADS_FAILURE:
      return {
        ...state,
        loading: false,
        messages: action.message,
      };
    case GET_LEAD:
      return {
        ...state,
        loadingGetLead: true,
        successGetLead: false,
        errorGetLead: false,
      };
    case GET_LEAD_SUCCESS:
      return {
        ...state,
        loadingGetLead: false,
        successGetLead: true,
        errorGetLead: false,
        lead: action.payload,
      };
    case GET_LEAD_FAILURE:
      return {
        ...state,
        loadingGetLead: false,
        successGetLead: false,
        errorGetLead: true,
        errorMessage: action.message,
      };
    case UPDATE_LEAD:
      return {
        ...state,
        loadingUpdateLead: true,
        successUpdateLead: false,
        errorUpdateLead: false,
      };
    case UPDATE_LEAD_SUCCESS:
      return {
        ...state,
        loadingUpdateLead: false,
        successUpdateLead: true,
        errorUpdateLead: false,
      };
    case UPDATE_LEAD_FAILURE:
      return {
        ...state,
        loadingUpdateLead: false,
        successUpdateLead: false,
        errorUpdateLead: true,
        errorMessage: action.message,
      };
    case GET_DUPLICATE:
      return {
        ...state,
        loadingGetDuplicate: true,
        successGetDuplicate: false,
        errorGetDuplicate: false,
      };
    case GET_DUPLICATE_SUCCESS:
      return {
        ...state,
        loadingGetDuplicate: false,
        successGetDuplicate: true,
        errorGetDuplicate: false,
        duplicate: action.payload,
      };
    case GET_DUPLICATE_FAILURE:
      return {
        ...state,
        loadingGetActivities: false,
        successGetActivities: false,
        errorGetActivities: true,
        errorMessageDuplicate: action.message,
      };
    case GET_ACTIVITIES:
      return {
        ...state,
        loadingGetActivities: true,
        successGetActivities: false,
        errorGetActivities: false,
      };
    case GET_ACTIVITIES_SUCCESS:
      return {
        ...state,
        loadingGetActivities: false,
        successGetActivities: true,
        errorGetActivities: false,
        activities: action.payload,
      };
    case GET_ACTIVITIES_FAILURE:
      return {
        ...state,
        loadingGetActivities: false,
        successGetActivities: false,
        errorGetActivities: true,
        errorMessageActivities: action.message,
      };
    case UPDATE_FILTER_LEADS:
      return {
        ...state,
        filterQuery: action.payload,
      };
    case CREATE_ACTIVITY:
      return {
        ...state,
        loadingCreateActivity: true,
        successCreateActivity: false,
        errorCreateActivity: false,
      };
    case CREATE_ACTIVITY_SUCCESS:
      return {
        ...state,
        loadingCreateActivity: false,
        successCreateActivity: true,
        errorCreateActivity: false,
      };
    case CREATE_ACTIVITY_FAILURE:
      return {
        ...state,
        loadingCreateActivity: false,
        successCreateActivity: false,
        errorCreateActivity: true,
        errorMessage: action.message,
      };
    case UPDATE_ACTIVITY:
      return {
        ...state,
        loadingUpdateActivity: true,
        successUpdateActivity: false,
        errorUpdateActivity: false,
      };
    case UPDATE_ACTIVITY_SUCCESS:
      return {
        ...state,
        loadingUpdateActivity: false,
        successUpdateActivity: true,
        errorUpdateActivity: false,
      };
    case UPDATE_ACTIVITY_FAILURE:
      return {
        ...state,
        loadingUpdateActivity: false,
        successUpdateActivity: false,
        errorUpdateLead: true,
        errorMessage: action.message,
      };
    case CREATE_LEAD:
      return {
        ...state,
        loadingCreateLead: true,
        successCreateLead: false,
        errorCreateLead: false,
      };
    case CREATE_LEAD_SUCCESS:
      return {
        ...state,
        loadingCreateLead: false,
        successCreateLead: true,
        errorCreateLead: false,
        lead: action.payload,
      };
    case CREATE_LEAD_FAILURE:
      return {
        ...state,
        loadingCreateLead: false,
        successCreateLead: false,
        errorCreateLead: true,
        errorMessage: action.message,
      };
    case GET_LEADS_SUGGESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        leadsSuggestions: action.payload,
      };
    case GET_LOG:
      return {
        ...state,
        loadingGetLogs: true,
        successGetLogs: false,
        errorGetLogs: false,
      };
    case GET_LOG_SUCCESS:
      return {
        ...state,
        loadingGetLogs: false,
        successGetLogs: true,
        errorGetLogs: false,
        logs: action.payload,
      };
    case GET_LOG_FAILURE:
      return {
        ...state,
        loadingGetLogs: false,
        successGetLogs: false,
        errorGetLogs: true,
        errorMessageLog: action.message,
      };
    case GET_LEADS_SUGGESTION_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CONVERT_TO_LEAD:
      return {
        ...state,
        loadingConvertToLead: true,
        successConvertToLead: false,
        errorConvertToLead: false,
      };
    case CONVERT_TO_LEAD_SUCCESS:
      return {
        ...state,
        loadingConvertToLead: false,
        successConvertToLead: true,
        errorConvertToLead: false,
      };
    case CONVERT_TO_LEAD_FAILURE:
      return {
        ...state,
        loadingConvertToLead: false,
        successConvertToLead: false,
        errorConvertToLead: true,
        errorMessage: action.message,
      };
    case LEAD_ASSIGN:
      return {
        ...state,
        loadingLeadAssigns: true,
        successLeadAssigns: false,
        errorLeadAssigns: false,
      };
    case LEAD_ASSIGN_SUCCESS:
      return {
        ...state,
        loadingLeadAssigns: false,
        successLeadAssigns: true,
        errorLeadAssigns: false,
      };
    case LEAD_ASSIGN_FAILURE:
      return {
        ...state,
        loadingLeadAssigns: false,
        successLeadAssigns: false,
        errorLeadAssigns: true,
        errorMessage: action.message,
      };
    case IMPORT_LEADS:
      return {
        ...state,
        loadingImportLeads: true,
        successImportLeads: false,
        errorImportLeads: false,
      };
    case IMPORT_LEADS_SUCCESS:
      return {
        ...state,
        loadingImportLeads: false,
        successImportLeads: true,
        errorImportLeads: false
      };
    case IMPORT_LEADS_FAILURE:
      return {
        ...state,
        loadingImportLeads: false,
        successImportLeads: false,
        errorImportLeads: true,
        errorMessage: action.message,
      };
    default:
      return state;
  }
};

export default leadsReducer;