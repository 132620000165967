export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE';
export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAILURE = 'GET_USER_INFO_FAILURE';

export type UserType = {
  user: any;
};
export interface UserLogin {
  type: typeof USER_LOGIN;
  error: boolean;
}
export interface UserLoginSuccess {
  type: typeof USER_LOGIN_SUCCESS;
  payload: any;
  messages: any;
}
export interface UserLoginFailure {
  type: typeof USER_LOGIN_FAILURE;
  error: boolean;
  messages: any;
}
export interface UserLogout {
  type: typeof USER_LOGOUT;
}
export interface UserLogoutSuccess {
  type: typeof USER_LOGOUT_SUCCESS;
}
export interface UserLogoutFailure {
  type: typeof USER_LOGOUT_FAILURE;
}
export interface UserInfo {
  type: typeof GET_USER_INFO;
}
export interface UserInfoSuccess {
  type: typeof GET_USER_INFO_SUCCESS;
  payload: UserType;
}
export interface UserInfoFailure {
  type: typeof GET_USER_INFO_FAILURE;
  error: boolean;
  messages: any;
}

export type UserDispatchTypes =
  | UserLogin
  | UserLoginSuccess
  | UserLoginFailure
  | UserLogout
  | UserLogoutSuccess
  | UserLogoutFailure
  | UserInfo
  | UserInfoSuccess
  | UserInfoFailure;
