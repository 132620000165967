import { Spin } from 'antd';
import {
    useEffect,
    useState
} from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import 'src/assets/css/notification.scss';
import Badge from "src/components/badge/badge";
import { NotificationInterface } from "./notification.interface";
import moment from 'moment';
import { NotificationMarkAsRead } from 'src/redux/settings/settings-action';
import {
    SELECTED_ALL,
    SELECTED_SINGLE
} from 'src/utils/constants/settings.constant';
import snackbar from 'src/components/snackbar/snackbar';
import { TYPE_ALERT_SUCCESS } from 'src/utils/constants/global.constants';
import {
    groupBy,
    values as toObject
} from 'lodash';
import NotificationContent from './notification-content';

const NotificationDrawer = ({ show, setShow, refresh }: NotificationInterface) => {
    const dispatch = useDispatch();
    const settings = useSelector((state: any) => state.settings);
    const { notification, notificationTotal, loadingGetNotification } = settings;
    const [dataNotification, setDataNotifications] = useState([]);

    useEffect(() => {
        if (notification?.length > 0) {
            groupingNotification()
        }
    }, [notification]);

    const groupingNotification = () => {
        const notificationByDate = notification?.map((data, id) => {
            data.group_by = moment(data.created_at).isSame(moment(), 'day') ? 'now' : 'older';
            return data
        })
        const groupingObject = groupBy(notificationByDate, 'group_by')
        const grupingNotification = toObject(groupingObject);
        grupingNotification?.map((data, i) => {
            grupingNotification[i]['date'] = Object.entries(groupingObject[Object.keys(groupingObject)[i]])[0][1].created_at
        })
        setDataNotifications(grupingNotification)
    }

    const handleNotification = async (item) => {
        if (!item?.is_read) {
            await dispatch(NotificationMarkAsRead(SELECTED_SINGLE, item?.notification_uuid))
            refresh();
        }
        setShow(false)
    }

    const markAllAsRead = async () => {
        if (notificationTotal.total_unread > 0) {
            await dispatch(NotificationMarkAsRead(SELECTED_ALL));
            refresh();
        }
        snackbar({
            type: TYPE_ALERT_SUCCESS,
            message: 'Successful mark all notification as read',
        });
    }


    return show && (
        <div className="notification-drawer">
            <div className="notification-drawer-header">
                <span className="notification-drawer-header-title">
                    Notification
                </span>
                <span className="notification-drawer-header-extra">
                    {notificationTotal && notificationTotal?.total_unread > 0 && (
                        <Badge data={notificationTotal && notificationTotal?.total_unread || 0} />
                    )}
                </span>
            </div>
            <div className="content">
                {notification && notification.length > 0 && (
                    <Spin spinning={loadingGetNotification}>
                        <div className="notification-drawer-panel">
                            <span className="notification-drawer-panel-status">
                                {
                                    dataNotification && dataNotification[0] && dataNotification[0].length > 0 ? 'New' : 'Older'
                                }
                            </span>
                            <span
                                className="notification-drawer-panel-read"
                                onClick={markAllAsRead}
                            >
                                Mark all as read
                            </span>
                        </div>
                        <div className="notification-drawer-body">
                            {
                                dataNotification && dataNotification[0] && dataNotification[0].length > 0 && dataNotification[0].map((x, i) =>
                                    NotificationContent({
                                        data: x,
                                        i: i + 1,
                                        handleNotification
                                    })
                                )
                            }
                        </div>
                        {
                            dataNotification && dataNotification[0] && dataNotification[0].length > 0 && dataNotification[1] && dataNotification[1].length > 0 && (
                                <>
                                    <div className="notification-drawer-panel">
                                        <span className="notification-drawer-panel-status">
                                            Older
                                        </span>
                                    </div>
                                    <div className="notification-drawer-body">
                                        {
                                            dataNotification && dataNotification[1] && dataNotification[1].length > 0 && dataNotification[1].map((x,i) => NotificationContent({
                                                data: x,
                                                i: i + 1,
                                                handleNotification
                                            }))
                                        }
                                    </div>
                                </>
                            )
                        }
                    </Spin>
                ) || (
                        <div className='no-more'>
                            No more notifcation to load
                        </div>
                    )}
            </div>
        </div>
    )
}
export default NotificationDrawer