import LazyDelay from 'src/utils/lazy-delay';

import {
  AccountIcon,
  AnalyticsIcon,
  ContactsIcon,
  ConsumersIcon,
  LeadsIcon,
  MenuIcon,
  OpportunitiesIcon,
  PermissionIcon,
  RoleIcon,
  SettingsIcon
} from 'src/components/icons/icon';

const AnalyticsPage = LazyDelay(import('src/views/analytics/'));
const ContactsPage = LazyDelay(import('src/views/contacts/'));
const ConsumersPage = LazyDelay(import('src/views/consumers'));
const LeadsPage = LazyDelay(import('src/views/leads/'));
const OpportunitiesPage = LazyDelay(import('src/views/opportunities/'));
const SettingsPage = LazyDelay(import('src/views/settings/'));
const SettingUserPage = LazyDelay(import('src/views/settings/users'));
const SettingRolesPage = LazyDelay(import('src/views/settings/roles'));
const SettingPermissionPage = LazyDelay(import('src/views/settings/permission'));
const SettingMenusPage = LazyDelay(import('src/views/settings/menus'));

export const AppRoutes: any = [{
  id: 1,
  parent_id: 0,
  name: 'Analytics',
  component: AnalyticsPage,
  icon: <AnalyticsIcon />,
}, {
  id: 2,
  parent_id: 0,
  name: 'Leads',
  component: LeadsPage,
  icon: <LeadsIcon />,
}, {
  id: 3,
  parent_id: 0,
  name: 'Opportunities',
  component: OpportunitiesPage,
  icon: <OpportunitiesIcon />,
}, {
  id: 4,
  parent_id: 0,
  name: 'Consumer',
  component: ConsumersPage,
  icon: <ConsumersIcon />,
}, {
  id: 5,
  parent_id: 0,
  name: 'Contacts',
  component: ContactsPage,
  icon: <ContactsIcon />,
}, {
  id: 6,
  parent_id: 0,
  name: 'Admin Settings',
  component: SettingsPage,
  icon: <SettingsIcon />,
}, {
  id: 7,
  parent_id: 6,
  name: 'User',
  component: SettingUserPage,
  icon: <AccountIcon />,
}, {
  id: 8,
  parent_id: 6,
  name: 'Role',
  component: SettingRolesPage,
  icon: <RoleIcon />,
}, {
  id: 9,
  parent_id: 6,
  name: 'Permission',
  component: SettingPermissionPage,
  icon: <PermissionIcon />,
}, {
  id: 10,
  parent_id: 6,
  name: 'Menu',
  component: SettingMenusPage,
  icon: <MenuIcon />,
}];
