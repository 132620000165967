import { Badge, Dropdown, Layout, Menu } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import { DownOutlined, BellFilled } from '@ant-design/icons'
import { PersonIcon } from "../icons/icon";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { userLogout } from "src/redux/auth/auth-action";
import NotificationDrawer from "../notification/notification";
import { useEffect, useState } from "react";
import { GetNotifications } from "src/redux/settings/settings-action";
import { endpoint } from 'src/config/env';
import { usePusher } from "src/utils/pusher";
import { TYPE_PUSHER_NOTIFICATION } from "src/utils/constants/global.constants";
const { Header } = Layout;
const { Item } = Menu;

import NotificationPusher from "../notification/notification-pusher";

const HeaderApp = () => {
    const pusher = usePusher();
    const dispatch = useDispatch();
    const history = useHistory();
    const auth = useSelector((state: any) => state.auth);
    const settings = useSelector((state: any) => state.settings);
    const { notificationTotal } = settings;
    const onLogOut = () => {
        dispatch(userLogout());
        history.push('/login');
    }
    const [unreadNotification, setUnreadNotification] = useState(0);

    useEffect(() => {
        setUnreadNotification(notificationTotal && notificationTotal?.total_unread || 0)
    }, [notificationTotal])

    const [showNotification, setShowNotification] = useState(false);

    const refreshNotifications = async () => {
        await dispatch(GetNotifications());
    }

    useEffect(() => {
        var channel = pusher.subscribe(`${endpoint.pusherChannel}`);
        TYPE_PUSHER_NOTIFICATION.map(x => {
            channel.bind(x, function (data) {
                if (auth?.user?.user_info?.uuid == data?.user_id) {
                    refreshNotifications();
                    return NotificationPusher({
                        data,
                        type: x
                    })
                }
                return () => {
                    channel.unbind(x);
                }
            });
        })
    }, []);

    return (
        <Header className="crm-developer-business-header">
            <div className='crm-developer-business-header-left'>
                <div id='searchBar'></div>
            </div>
            <div className='crm-developer-business-header-right profile-container'>
                <Badge count={unreadNotification} className="notification">
                    <BellFilled
                        onClick={() => {
                            refreshNotifications()
                            setShowNotification(showNotification ? false : true)
                        }}
                    />
                </Badge>
                <div className='divider' />
                <Dropdown
                    overlay={
                        <Menu className='dropdown-profile'>
                            <Item>
                                <div className='dropdown-profile-header'>
                                    <span>
                                        <Avatar
                                            style={{ backgroundColor: '#E7E8EB', marginRight: '10px', marginTop: '5px' }}
                                            icon={<PersonIcon size={16} color='#868D9A' />}
                                        />
                                    </span>
                                    <span>
                                        <div className="dropdown-profile-header-name">
                                            {auth.user?.user_info?.name || '...'}
                                        </div>
                                        <div className="dropdown-profile-header-detail">
                                            <span>{auth.user?.user_info?.role?.name || '...'}</span>
                                            <div className='divider' />
                                            <span>{auth.user?.user_info?.email || '...'}</span>
                                        </div>
                                    </span>
                                </div>
                            </Item>
                            <Item
                                className='dropdown-profile-logout'
                                onClick={onLogOut}
                            >
                                Log Out
                            </Item>
                        </Menu>
                    }
                >
                    <span className="profile">
                        <Avatar
                            style={{ backgroundColor: '#E7E8EB', marginRight: '8px', width: '32px', height: '32px' }}
                            icon={<PersonIcon size={16} color='#868D9A' />}
                        />
                        {auth.user?.user_info?.email || '...'} <DownOutlined style={{ marginLeft: '8px' }} />
                    </span>

                </Dropdown>
            </div>
            <NotificationDrawer
                show={showNotification}
                setShow={setShowNotification}
                refresh={refreshNotifications}
            />
        </Header>
    )
}
export default HeaderApp