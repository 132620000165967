import { Layout, Menu, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
const { Sider } = Layout;
/* eslint-disable import/first */
import Logo from 'src/assets/icons/logo-white.svg';
import '../../assets/css/sidebar.scss';
import { EmailIcon } from '../icons/icon';

const SidebarNavigation = ({ data }) => {
  let history = useHistory();
  const location = useLocation();
  const [active, setActive] = useState('/');
  const [menus, setMenus] = useState([]);
  useEffect(() => {
    if (data) {
      setMenus([
        {
          id: 99,
          children: [],
          icon: <EmailIcon />,
          parent_id: 0,
          name: 'Email Config',
          url: '/email',
        },
        ...data,
      ]);
    }
  }, [data]);
  const renderSubmenu = (data, type = '') => {
    let elementSubmenu = [];
    elementSubmenu.push(
      <Menu.Item key={`${data.path}`} className='header-submenu'>
        <span className={`${type}`}>{data.name}</span>
      </Menu.Item>,
    );
    data.children?.map(submenu => {
      if (submenu.children.length > 0) {
        elementSubmenu.push(
          <Menu.SubMenu key={submenu.url} title={<span style={{ position: 'absolute', marginLeft: '20px' }}>{submenu.name}</span>}>
            {renderSubmenu(submenu, 'grandsubmenu')}
          </Menu.SubMenu>,
        );
      } else {
        elementSubmenu.push(
          <Menu.Item
            key={`${submenu.url}`}
            icon={submenu.icon}
            onClick={() => {
              history.replace(`${submenu.url}`);
            }}
          >
            <span className={`${type}`}>{submenu.name}</span>
          </Menu.Item>,
        );
      }
    });
    return elementSubmenu;
  };

  useEffect(() => {
    const matchText = location.pathname;
    var lastChar = location.pathname.substring(location.pathname.length - 1);
    setActive(lastChar == '/' ? matchText.substring(0, matchText.length - 1) : matchText);
  }, [location]);
  return (
    <Sider collapsed={true} className='sidebar'>
      <div className='sidebar-logo'>
        <img alt='logo' src={Logo} />
      </div>
      <Menu mode='inline' defaultSelectedKeys={[`${location.pathname}`]} selectedKeys={[`${active}`]}>
        {menus &&
          menus.length > 0 &&
          menus.map(menu => {
            let data = [];
            if (menu.children.length > 0) {
              data.push(
                <Menu.SubMenu key={menu.url} icon={menu.icon} title={menu.name}>
                  {renderSubmenu(menu)}
                </Menu.SubMenu>,
              );
            } else {
              <Tooltip placement='right' title={menu.name}>
                {data.push(
                  <Menu.Item
                    key={menu.url}
                    onClick={() => {
                      history.replace(`${menu.url}?reset=true`);
                    }}
                    icon={menu.icon}
                  >
                    {menu.name}
                  </Menu.Item>,
                )}
              </Tooltip>;
            }
            return data;
          })}
      </Menu>
    </Sider>
  );
};
export default SidebarNavigation;
