import { Dispatch } from 'redux';
import { SELECTED_ALL, SELECTED_SINGLE } from 'src/utils/constants/settings.constant';
import {
  createSettingsMenuService,
  createSettingsPermissionService,
  createSettingsRoleService,
  deleteSettingsMenuService,
  deleteSettingsPermissionService,
  deleteSettingsRoleService,
  getSettingRoleDetail,
  getSettingsMenuService,
  getSettingsMenusService,
  getSettingsPermissionService,
  getSettingsPermissionsService,
  getSettingsRolesService,
  getSettingsUsersService,
  getPermissionOnRole,
  updatePermissionOnRole,
  orderingSettingsMenuService,
  updateSettingsMenuService,
  updateSettingsPermissionService,
  updateSettingsRoleService,
  getSettingsUserService,
  createSettingsUserService,
  updateSettingsUserService,
  deleteSettingsUserService,
  getRoleOptions,
  getUserOptions,
  getNotificationsService,
  notificationMarkAsDoneService,
  notificationMarkAllAsDoneService,
} from './settings-service';
import {
  GET_SETTINGS_PERMISSIONS,
  GET_SETTINGS_PERMISSIONS_SUCCESS,
  GET_SETTINGS_PERMISSIONS_FAILURE,
  SettingsDispatchTypes,
  GET_SETTINGS_ROLES,
  GET_SETTINGS_ROLES_SUCCESS,
  GET_SETTINGS_ROLES_FAILURE,
  GET_SETTINGS_ROLE,
  GET_SETTINGS_ROLE_SUCCESS,
  GET_SETTINGS_ROLE_FAILURE,
  CREATE_SETTINGS_ROLE,
  CREATE_SETTINGS_ROLE_SUCCESS,
  CREATE_SETTINGS_ROLE_FAILURE,
  UPDATE_SETTINGS_ROLE_SUCCESS,
  UPDATE_SETTINGS_ROLE_FAILURE,
  UPDATE_SETTINGS_ROLE,
  DELETE_SETTINGS_ROLE,
  DELETE_SETTINGS_ROLE_SUCCESS,
  DELETE_SETTINGS_ROLE_FAILURE,
  GET_SETTINGS_ROLE_PERMISSION,
  GET_SETTINGS_ROLE_PERMISSION_SUCCESS,
  GET_SETTINGS_ROLE_PERMISSION_FAILURE,
  UPDATE_SETTINGS_ROLE_PERMISSION,
  UPDATE_SETTINGS_ROLE_PERMISSION_SUCCESS,
  UPDATE_SETTINGS_ROLE_PERMISSION_FAILURE,
  GET_SETTINGS_PERMISSION,
  GET_SETTINGS_PERMISSION_SUCCESS,
  GET_SETTINGS_PERMISSION_FAILURE,
  CREATE_SETTINGS_PERMISSION,
  CREATE_SETTINGS_PERMISSION_SUCCESS,
  CREATE_SETTINGS_PERMISSION_FAILURE,
  UPDATE_SETTINGS_PERMISSION_SUCCESS,
  UPDATE_SETTINGS_PERMISSION_FAILURE,
  UPDATE_SETTINGS_PERMISSION,
  DELETE_SETTINGS_PERMISSION,
  DELETE_SETTINGS_PERMISSION_SUCCESS,
  DELETE_SETTINGS_PERMISSION_FAILURE,
  GET_SETTINGS_MENUS,
  GET_SETTINGS_MENUS_SUCCESS,
  GET_SETTINGS_MENUS_FAILURE,
  GET_SETTINGS_MENU,
  GET_SETTINGS_MENU_SUCCESS,
  GET_SETTINGS_MENU_FAILURE,
  CREATE_SETTINGS_MENU,
  CREATE_SETTINGS_MENU_SUCCESS,
  CREATE_SETTINGS_MENU_FAILURE,
  UPDATE_SETTINGS_MENU,
  UPDATE_SETTINGS_MENU_SUCCESS,
  UPDATE_SETTINGS_MENU_FAILURE,
  DELETE_SETTINGS_MENU_SUCCESS,
  DELETE_SETTINGS_MENU_FAILURE,
  DELETE_SETTINGS_MENU,
  ORDERING_SETTINGS_MENU,
  ORDERING_SETTINGS_MENU_SUCCESS,
  ORDERING_SETTINGS_MENU_FAILURE,
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  OPTION_USERS,
  OPTION_USERS_SUCCESS,
  OPTION_USERS_FAILURE,
  OPTION_ROLES,
  OPTION_ROLES_SUCCESS,
  OPTION_ROLES_FAILURE,
  GET_NOTIFICATION,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILURE,
  MARK_AS_READ,
  MARK_AS_READ_SUCCESS,
  MARK_AS_READ_FAILURE,
} from './settings-types';

export const GetSettingsPermissions = (payload: any) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
  try {
    dispatch({
      type: GET_SETTINGS_PERMISSIONS,
    });
    const res: any = await getSettingsPermissionsService(payload);

    dispatch({
      type: GET_SETTINGS_PERMISSIONS_SUCCESS,
      payload: res,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: GET_SETTINGS_PERMISSIONS_FAILURE,
    });
  }
};

export const GetSettingsPermissionDetail = (id: any) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
  try {
    dispatch({
      type: GET_SETTINGS_PERMISSION,
    });
    const res: any = await getSettingsPermissionService(id);

    dispatch({
      type: GET_SETTINGS_PERMISSION_SUCCESS,
      payload: res.data,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: GET_SETTINGS_PERMISSION_FAILURE,
      message: e.message,
    });
  }
};

export const CreateSettingsPermision = (payload: any) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
  try {
    dispatch({
      type: CREATE_SETTINGS_PERMISSION,
    });

    let res: any = await createSettingsPermissionService(payload);
    dispatch({
      type: CREATE_SETTINGS_PERMISSION_SUCCESS,
      payload: res.data,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: CREATE_SETTINGS_PERMISSION_FAILURE,
      message: e.message,
    });
    return e;
  }
};

export const UpdateSettingsPermision = (payload: any, id: any) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
  try {
    dispatch({
      type: UPDATE_SETTINGS_PERMISSION,
    });
    let res: any = await updateSettingsPermissionService(payload, id);
    dispatch({
      type: UPDATE_SETTINGS_PERMISSION_SUCCESS,
      payload: res.message,
    });
    return res;
  } catch (e: any) {
    dispatch({
      type: UPDATE_SETTINGS_PERMISSION_FAILURE,
      message: e.message,
    });
    return e;
  }
};

export const DeleteSettingsPermision = (id: number) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
  try {
    dispatch({
      type: DELETE_SETTINGS_PERMISSION,
    });

    let res: any = await deleteSettingsPermissionService(id);

    dispatch({
      type: DELETE_SETTINGS_PERMISSION_SUCCESS,
      message: res.message,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: DELETE_SETTINGS_PERMISSION_FAILURE,
      message: e.message,
    });
  }
};

export const GetSettingsRoles = (payload: any) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
  try {
    dispatch({
      type: GET_SETTINGS_ROLES,
    });
    const res: any = await getSettingsRolesService(payload);

    dispatch({
      type: GET_SETTINGS_ROLES_SUCCESS,
      payload: res,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: GET_SETTINGS_ROLES_FAILURE,
    });
  }
};

export const GetSettingRoleDetail = (id: any) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
  try {
    dispatch({
      type: GET_SETTINGS_ROLE,
    });
    const res: any = await getSettingRoleDetail(id);

    dispatch({
      type: GET_SETTINGS_ROLE_SUCCESS,
      payload: res.data,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: GET_SETTINGS_ROLE_FAILURE,
      message: e.message,
    });
  }
};

export const CreateSettingsRole = (payload: any) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
  try {
    dispatch({
      type: CREATE_SETTINGS_ROLE,
    });

    let res: any = await createSettingsRoleService(payload);
    dispatch({
      type: CREATE_SETTINGS_ROLE_SUCCESS,
      payload: res.data,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: CREATE_SETTINGS_ROLE_FAILURE,
      message: e.message,
    });
    return e;
  }
};

export const UpdateSettingRole = (payload: any, id: any) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
  try {
    dispatch({
      type: UPDATE_SETTINGS_ROLE,
    });
    let res: any = await updateSettingsRoleService(payload, id);
    dispatch({
      type: UPDATE_SETTINGS_ROLE_SUCCESS,
      payload: res.message,
    });
    return res;
  } catch (e: any) {
    dispatch({
      type: UPDATE_SETTINGS_ROLE_FAILURE,
      message: e.message,
    });
    return e;
  }
};

export const DeleteSettingsRole = (id: number) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
  try {
    dispatch({
      type: DELETE_SETTINGS_ROLE,
    });

    let res: any = await deleteSettingsRoleService(id);

    dispatch({
      type: DELETE_SETTINGS_ROLE_SUCCESS,
      message: res.message,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: DELETE_SETTINGS_ROLE_FAILURE,
      message: e.message,
    });
  }
};

export const GetSettingRolePermission = (payload: any, id: number) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
  try {
    dispatch({
      type: GET_SETTINGS_ROLE_PERMISSION,
    });
    const res: any = await getPermissionOnRole(payload, id);

    dispatch({
      type: GET_SETTINGS_ROLE_PERMISSION_SUCCESS,
      payload: res.data,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: GET_SETTINGS_ROLE_PERMISSION_FAILURE,
    });
  }
};

export const UpdateSettingRolePermission = (payload: any, id: any) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
  try {
    dispatch({
      type: UPDATE_SETTINGS_ROLE_PERMISSION,
    });
    let res: any = await updatePermissionOnRole(payload, id);
    dispatch({
      type: UPDATE_SETTINGS_ROLE_PERMISSION_SUCCESS,
      payload: res.message,
    });
    return res;
  } catch (e: any) {
    dispatch({
      type: UPDATE_SETTINGS_ROLE_PERMISSION_FAILURE,
      message: e.message,
    });
    return e;
  }
};

export const GetSettingsMenus = () => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
  try {
    dispatch({
      type: GET_SETTINGS_MENUS,
    });
    const res: any = await getSettingsMenusService();

    dispatch({
      type: GET_SETTINGS_MENUS_SUCCESS,
      payload: res,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: GET_SETTINGS_MENUS_FAILURE,
    });
  }
};

export const GetSettingsMenuDetail = (id: any) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
  try {
    dispatch({
      type: GET_SETTINGS_MENU,
    });
    const res: any = await getSettingsMenuService(id);

    dispatch({
      type: GET_SETTINGS_MENU_SUCCESS,
      payload: res.data,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: GET_SETTINGS_MENU_FAILURE,
      message: e.message,
    });
  }
};

export const CreateSettingsMenu = (payload: any) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
  try {
    dispatch({
      type: CREATE_SETTINGS_MENU,
    });

    let res: any = await createSettingsMenuService(payload);
    dispatch({
      type: CREATE_SETTINGS_MENU_SUCCESS,
      payload: res.data,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: CREATE_SETTINGS_MENU_FAILURE,
      message: e.message,
    });
    return e;
  }
};

export const UpdateSettingsMenu = (payload: any, id: any) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
  try {
    dispatch({
      type: UPDATE_SETTINGS_MENU,
    });
    let res: any = await updateSettingsMenuService(payload, id);
    dispatch({
      type: UPDATE_SETTINGS_MENU_SUCCESS,
      payload: res.message,
    });
    return res;
  } catch (e: any) {
    dispatch({
      type: UPDATE_SETTINGS_MENU_FAILURE,
      message: e.message,
    });
    return e;
  }
};

export const DeleteSettingsMenu = (id: number) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
  try {
    dispatch({
      type: DELETE_SETTINGS_MENU,
    });

    let res: any = await deleteSettingsMenuService(id);

    dispatch({
      type: DELETE_SETTINGS_MENU_SUCCESS,
      message: res.message,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: DELETE_SETTINGS_MENU_FAILURE,
      message: e.message,
    });
  }
};

export const OrderingettingsMenu = (payload: any) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
  try {
    dispatch({
      type: ORDERING_SETTINGS_MENU,
    });

    let res: any = await orderingSettingsMenuService(payload);
    dispatch({
      type: ORDERING_SETTINGS_MENU_SUCCESS,
      payload: res.data,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: ORDERING_SETTINGS_MENU_FAILURE,
      message: e.message,
    });
    return e;
  }
};

export const GetSettingsUsers = (params: any) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
  try {
    dispatch({
      type: GET_USERS,
    });
    const res: any = await getSettingsUsersService(params);

    dispatch({
      type: GET_USERS_SUCCESS,
      payload: res,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: GET_USERS_FAILURE,
    });
  }
};

export const GetSettingsUserDetail = (id: any) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
  try {
    dispatch({
      type: GET_USER,
    });
    const res: any = await getSettingsUserService(id);

    dispatch({
      type: GET_USER_SUCCESS,
      payload: res.data,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: GET_USER_FAILURE,
      message: e.message,
    });
  }
};

export const CreateSettingsUser = (payload: any) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
  try {
    dispatch({
      type: CREATE_USER,
    });

    let res: any = await createSettingsUserService(payload);
    dispatch({
      type: CREATE_USER_SUCCESS,
      payload: res.data,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: CREATE_USER_FAILURE,
      message: e.message,
    });
    return e;
  }
};

export const UpdateSettingsUser = (payload: any, id: any) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
  try {
    dispatch({
      type: UPDATE_USER,
    });
    let res: any = await updateSettingsUserService(payload, id);
    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: res.message,
    });
    return res;
  } catch (e: any) {
    dispatch({
      type: UPDATE_USER_FAILURE,
      message: e.message,
    });
    return e;
  }
};

export const DeleteSettingsUser = (id: number) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
  try {
    dispatch({
      type: DELETE_USER,
    });

    let res: any = await deleteSettingsUserService(id);

    dispatch({
      type: DELETE_USER_SUCCESS,
      message: res.message,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: DELETE_USER_FAILURE,
      message: e.message,
    });
  }
};

export const OptionUsers = payload => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
  try {
    dispatch({
      type: OPTION_USERS,
    });

    let res: any = await getUserOptions(payload);

    dispatch({
      type: OPTION_USERS_SUCCESS,
      payload: res.data,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: OPTION_USERS_FAILURE,
      message: e.message,
    });
  }
};

export const OptionRoles = () => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
  try {
    dispatch({
      type: OPTION_ROLES,
    });

    let res: any = await getRoleOptions();

    dispatch({
      type: OPTION_ROLES_SUCCESS,
      payload: res.data,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: OPTION_ROLES_FAILURE,
      message: e.message,
    });
  }
};

export const GetNotifications = () => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
  try {
    dispatch({
      type: GET_NOTIFICATION,
    });

    const res = await getNotificationsService();

    dispatch({
      type: GET_NOTIFICATION_SUCCESS,
      payload: res.data,
      message: res.message,
      total: res.meta,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: GET_NOTIFICATION_FAILURE,
    });
  }
};

export const NotificationMarkAsRead = (type: String, payload?: any) => async (dispatch: Dispatch<SettingsDispatchTypes>) => {
  try {
    dispatch({
      type: MARK_AS_READ,
    });

    let res: any = [];

    if (type === SELECTED_SINGLE) {
      res = await notificationMarkAsDoneService(payload);
    }

    if (type === SELECTED_ALL) {
      res = await notificationMarkAllAsDoneService();
    }

    dispatch({
      type: MARK_AS_READ_SUCCESS,
      payload: res.data,
    });

    return res;
  } catch (e: any) {
    dispatch({
      type: MARK_AS_READ_FAILURE,
      message: e.message,
    });
  }
};