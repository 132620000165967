import '../../assets/css/layout.scss';
import { Layout } from 'antd';
import SidebarNavigation from '../sidebar/sidebar';
import HeaderApp from './header';

const { Content } = Layout;

const LayoutApp = (props: any) => {
    const RenderContent = () => {
        return <Content className='ant-layout-content-main' style={{ overflowY: 'auto', maxHeight: '10vh', }}>{props.children}</Content>;
    };
    return (
        <Layout className='crm-developer-business'>
            <SidebarNavigation data={props.menus} />
            <Layout>
                <HeaderApp />
                {!props.loading && RenderContent()}
            </Layout>
        </Layout>
    );
}

export default LayoutApp