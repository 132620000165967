import moment from 'moment';
import { clearAll, getVersionApp } from './storage';
import packageJson from '../../package.json';
import { BaseOptionType } from 'antd/lib/select';

export const queryParam = payload => {
  let params = new URLSearchParams();

  for (let key in payload) {
    if (payload[key] || typeof payload[key] === 'boolean') {
      if (!Array.isArray(payload[key])) {
        const param = payload[key];
        params.append(key, param);
      } else {
        for (let index in payload[key]) {
          const param = payload[key][index];

          params.append(key, param.toString().replace('+', ' '));
        }
      }
    }
  }

  return params;
};

export const capitalizeCharacter = string => {
  return string.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
};

export const convertDate = date => {
  var newDate = new Date(date);
  return date ? moment(newDate).format('DD/MM/YYYY') : '';
};

export const convertTime = time => {
  var newTime = new Date(time);
  return time ? moment(newTime).format('HH:mm:ss') : '';
};

export const getBuildDate = epoch => {
  const buildDate = moment(epoch).format('DD-MM-YYY HH:MM');
  return buildDate;
};

export const checkingVersionapp = () => {
  const latestVersion = moment(parseInt(getVersionApp() || null));
  const currenctVersion = moment(packageJson.buildDate);
  if (currenctVersion.isAfter(latestVersion)) {
    clearAll();
    window.location.href = '/login';
  }
  return;
};

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export const allowOnlyNumberFromTextInput = e => {
  const specialCharRegex = /^(?=.*\d)[\d ]+$/;
  const pressedKey = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if (!specialCharRegex.test(pressedKey)) {
    e.preventDefault();
    return false;
  } else {
    return true;
  }
};

export const getByPath = (object, path) => {
  if (!path) {
    return;
  }

  return path.split('.').reduce((acc, value) => acc[value], object);
};

export const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map(item => [item[key], item])).values()];
};

export const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

export const disabledDate = current => {
  // Can not select days before today and today
  return current && current <= moment().endOf('day');
};

export const disabledDateTime = () => {
  return {
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  };
};

export const enumToOptions = (enums: { [key: string]: any }): BaseOptionType[] => {
  return Object.keys(enums)
    .filter(key => isNaN(parseInt(key)))
    .map(key => {
      return {
        label: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
        value: enums[key],
      };
    });
};
