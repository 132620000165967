import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import LayoutApp from 'src/components/layout/layout-app';
import RouteChildren from "src/components/route/route-children";
import { GetUserPreference } from "src/redux/auth/auth-action";
import { GetNotifications } from "src/redux/settings/settings-action";
import { syncPreference } from "src/utils/helpers";
import { isLoggedIn } from "src/utils/storage";
import '../assets/css/App.scss';

const App = ({ fallback }) => {
    const dispatch = useDispatch();
    const { loadingCreateMenu, loadingDeleteMenu, loadingUpdateMenu, loadingOrderingMenu } = useSelector((state: any) => state.settings);
    const { user } = useSelector((state: any) => state.auth);
    const [loading, setLoading] = useState(false);
    const initialPreference = {
        menu: [],
        routes: [],
        permissions: []
    }
    const [preference, setPreference] = useState(initialPreference)
    
    useEffect(() => {
        if (!loadingCreateMenu || !loadingDeleteMenu || !loadingUpdateMenu || !loadingOrderingMenu) {
            if (isLoggedIn()) {
                if (user) {
                    syncMenusAndPermissions();
                    syncNotifications();
                }
            }
        }
    }, [loadingCreateMenu, loadingDeleteMenu, loadingUpdateMenu, loadingOrderingMenu]);
    
    const syncNotifications = async () => {
        await dispatch(GetNotifications())
    }
    
    const syncMenusAndPermissions = async () => {
        const userInfo: any = await dispatch(GetUserPreference())
        const userPreference: any = await syncPreference(userInfo?.data || undefined)
        setLoading(true);
        if (userPreference) {
            setPreference(userPreference || initialPreference)
            setLoading(false);
        } else {
            setLoading(false);
        }
        setLoading(false);
    }

    if (!isLoggedIn()) {
        return <Redirect to='/login' />;
    }

    return (
        <LayoutApp loading={loading} menus={preference?.menu} syncNotifications={syncNotifications}>
            <RouteChildren routes={preference?.routes} fallback={fallback} permissions={preference?.permissions} />
        </LayoutApp>
    )
}

export default App