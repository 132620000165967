import { AppRoutes } from 'src/routes/routes-app';
import { treeify, untreeify } from 'treeify-js';
import { NAVBAR_OPTION } from './constants/global.constants';

import LazyDelay from './lazy-delay';

const EmailConfigPage = LazyDelay(import('src/views/emails'))
const Error404 = LazyDelay(import('src/views/error/404'));

export const autoCompleteOptionTransformer = (data: any) => {
    let transformed = []

    if (!data) {
        return transformed
    }

    if (data.length == 0) {
        return transformed
    }

    NAVBAR_OPTION.map(x => {
        if (data?.[x.index] != null) {
            let options = []
            data?.[x.index].forEach((element) => {
                options.push({
                    'key': element[x.value] + '|' + x?.title,
                    'value': element[x.label] + '|' + x?.title,
                    'label': element[x.label]
                })
            })
            transformed.push({
                'label': x.title,
                'options': options
            })
        }
    })

    return transformed
}

export const autoCompleteOptionTransformerSingle = (data: any) => {
    let transformed = []

    if (!data) {
        return transformed
    }
    if (data.length == 0) {
        return transformed
    }

    const title = 'Leads Name'
    let options = []
    data.forEach((element: any) => {
        options.push({
            'key': element.value + '|' + title,
            'value': element.label,
            'label': element.label
        })
    });
    transformed.push({
        'label': title,
        'options': options
    })
    return transformed
}

export const paginate = (array, page_size, page_number) => {
    return array.slice((page_number - 1) * page_size, page_number * page_size);
}

export const syncPreference = async (data) => {
    const { menus, permissions } = data
    const userMenus = untreeify(menus);
    const parsingMenu = userMenus.map(item => {
        const routeApp = AppRoutes.find(x => x.name === item.name);
        let _newObject = {
            ...item,
            icon: routeApp?.icon,
        };
        return _newObject;
    });
    const parsingRoutes = AppRoutes.map(item => {
        const menu = userMenus.find(x => x.name === item.name);
        let _newObject = {
            ...item,
            menu: menu ? true : false,
            path: menu?.url || `${item?.id}`,
        };
        return _newObject;
    }).filter(x => x.menu === true);
    parsingRoutes.push({ path: '/email', name: 'EmailConfigPage', component: EmailConfigPage });
    parsingRoutes.push({ path: '*', name: 'Error404', component: Error404 });
    return data ? {
        menu: treeify(parsingMenu, {
            parentId: 'parent_id',
            multi: true,
        }),
        routes: parsingRoutes,
        permissions
    } : undefined
}