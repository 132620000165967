import React from 'react';
import ReactDOM from 'react-dom';
import BaseApp from './BaseApp';
import * as serviceWorker from './serviceWorker';
import { configureStore } from './redux/store';
import './index.css';

const store = configureStore({});

ReactDOM.render(
    <React.StrictMode>
        <BaseApp store={store} />
    </React.StrictMode>
, document.getElementById('root'));
serviceWorker.unregister();
