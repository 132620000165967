export const GET_LEADS = 'GET_LEADS';
export const GET_LEADS_SUCCESS = 'GET_LEADS_SUCCESS';
export const GET_LEADS_FAILURE = 'GET_LEADS_FAILURE';
export const GET_LEAD = 'GET_LEAD';
export const GET_LEAD_SUCCESS = 'GET_LEAD_SUCCESS';
export const GET_LEAD_FAILURE = 'GET_LEAD_FAILURE';
export const UPDATE_LEAD = 'UPDATE_LEAD';
export const UPDATE_LEAD_SUCCESS = 'UPDATE_LEAD_SUCCESS';
export const UPDATE_LEAD_FAILURE = 'UPDATE_LEAD_FAILURE';
export const CREATE_LEAD = 'CREATE_LEAD';
export const CREATE_LEAD_SUCCESS = 'CREATE_LEAD_SUCCESS';
export const CREATE_LEAD_FAILURE = 'CREATE_LEAD_FAILURE';

export const UPDATE_FILTER_LEADS = 'UPDATE_FILTER_LEADS';

export const GET_DUPLICATE = 'GET_DUPLICATE';
export const GET_DUPLICATE_SUCCESS = 'GET_DUPLICATE_SUCCESS';
export const GET_DUPLICATE_FAILURE = 'GET_DUPLICATE_FAILURE';

export const GET_ACTIVITIES = 'GET_ACTIVITIES';
export const GET_ACTIVITIES_SUCCESS = 'GET_ACTIVITIES_SUCCESS';
export const GET_ACTIVITIES_FAILURE = 'GET_ACTIVITIES_FAILURE';
export const CREATE_ACTIVITY = 'CREATE_ACTIVITY';
export const CREATE_ACTIVITY_SUCCESS = 'CREATE_ACTIVITY_SUCCESS';
export const CREATE_ACTIVITY_FAILURE = 'CREATE_ACTIVITY_FAILURE';
export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY';
export const UPDATE_ACTIVITY_SUCCESS = 'UPDATE_ACTIVITY_SUCCESS';
export const UPDATE_ACTIVITY_FAILURE = 'UPDATE_ACTIVITY_FAILURE';

export const GET_LEADS_SUGGESTION_SUCCESS = 'GET_LEADS_SUGGESTION_SUCCESS';
export const GET_LEADS_SUGGESTION_FAILURE = 'GET_LEADS_SUGGESTION_FAILURE';

export const GET_LOG = 'GET_LOG';
export const GET_LOG_SUCCESS = 'GET_LOG_SUCCESS';
export const GET_LOG_FAILURE = 'GET_LOG_FAILURE';

export const CONVERT_TO_LEAD = 'CONVERT_TO_LEAD';
export const CONVERT_TO_LEAD_SUCCESS = 'CONVERT_TO_LEAD_SUCCESS';
export const CONVERT_TO_LEAD_FAILURE = 'CONVERT_TO_LEAD_FAILURE';

export const LEAD_ASSIGN = 'LEAD_ASSIGN';
export const LEAD_ASSIGN_SUCCESS = 'LEAD_ASSIGN_SUCCESS';
export const LEAD_ASSIGN_FAILURE = 'CREATE_ACTIVITY_FAILURE';

export const IMPORT_LEADS = 'IMPORT_LEADS';
export const IMPORT_LEADS_SUCCESS = 'IMPORT_LEADS_SUCCESS';
export const IMPORT_LEADS_FAILURE = 'IMPORT_LEADS_FAILURE';

export type LeadsType = {
  data: any;
};
export interface GetLeads {
  type: typeof GET_LEADS;
}
export interface GetLeadsSuccess {
  type: typeof GET_LEADS_SUCCESS;
  payload: LeadsType;
}
export interface GetLeadsFailure {
  type: typeof GET_LEADS_FAILURE;
  message: string;
}
export interface GetLead {
  type: typeof GET_LEAD;
}
export interface GetLeadSuccess {
  type: typeof GET_LEAD_SUCCESS;
  payload: any;
}
export interface GetLeadFailure {
  type: typeof GET_LEAD_FAILURE;
  message: any;
}
export interface UpdateLead {
  type: typeof UPDATE_LEAD;
}
export interface UpdateLeadSuccess {
  type: typeof UPDATE_LEAD_SUCCESS;
  payload: any;
}
export interface UpdateLeadFailure {
  type: typeof UPDATE_LEAD_FAILURE;
  message: any;
}
export interface UpdateFilterLeads {
  type: typeof UPDATE_FILTER_LEADS;
  payload: any;
}
export interface GetDuplicate {
  type: typeof GET_DUPLICATE;
}
export interface GetDuplicateSuccess {
  type: typeof GET_DUPLICATE_SUCCESS;
  payload: any;
}
export interface GetDuplicateFailure {
  type: typeof GET_DUPLICATE_FAILURE;
  message: any;
}
//Activities
export interface GetActivities {
  type: typeof GET_ACTIVITIES;
}
export interface GetActivitiesSuccess {
  type: typeof GET_ACTIVITIES_SUCCESS;
  payload: any;
}
export interface GetActivitiesFailure {
  type: typeof GET_ACTIVITIES_FAILURE;
  message: any;
}
export interface CreateActivity {
  type: typeof CREATE_ACTIVITY;
}
export interface CreateActivitySuccess {
  type: typeof CREATE_ACTIVITY_SUCCESS;
}
export interface CreateActivityFailure {
  type: typeof CREATE_ACTIVITY_FAILURE;
  message: any;
}
export interface UpdateActivity {
  type: typeof UPDATE_ACTIVITY;
}
export interface UpdateActivitySuccess {
  type: typeof UPDATE_ACTIVITY_SUCCESS;
  payload: any;
}
export interface UpdateActivityFailure {
  type: typeof UPDATE_ACTIVITY_FAILURE;
  message: any;
}
export interface CreateLead {
  type: typeof CREATE_LEAD;
}
export interface CreateLeadSuccess {
  type: typeof CREATE_LEAD_SUCCESS;
  payload: any;
}
export interface CreateLeadFailure {
  type: typeof CREATE_LEAD_FAILURE;
  message: any;
}
export interface GetLeadsSuggestionSuccess {
  type: typeof GET_LEADS_SUGGESTION_SUCCESS;
  payload: LeadsType;
}
export interface GetLeadsSuggestionFailure {
  type: typeof GET_LEADS_SUGGESTION_FAILURE;
}
export interface GetLog {
  type: typeof GET_LOG;
}
export interface GetLogSuccess {
  type: typeof GET_LOG_SUCCESS;
  payload: any;
}
export interface GetLogFailure {
  type: typeof GET_LOG_FAILURE;
  message: any;
}
export interface ConvertToLead {
  type: typeof CONVERT_TO_LEAD;
}

export interface ConvertToLeadSuccess {
  type: typeof CONVERT_TO_LEAD_SUCCESS;
  message: string;
}

export interface ConvertToLeadFailure {
  type: typeof CONVERT_TO_LEAD_FAILURE;
  message: string;
}

export interface LeadAssigns {
  type: typeof LEAD_ASSIGN;
}

export interface LeadAssignsSuccess {
  type: typeof LEAD_ASSIGN_SUCCESS;
  message: string;
}

export interface LeadAssignsFailure {
  type: typeof LEAD_ASSIGN_FAILURE;
  message: string;
}
export interface ImportLeads {
  type: typeof IMPORT_LEADS;
}
export interface ImportLeadsSuccess {
  type: typeof IMPORT_LEADS_SUCCESS;
}
export interface ImportLeadsFailure {
  type: typeof IMPORT_LEADS_FAILURE;
  message: any;
}

export type LeadsDispatchTypes =
  | GetLeads
  | GetLeadsSuccess
  | GetLeadsFailure
  | GetLead
  | GetLeadSuccess
  | GetLeadFailure
  | UpdateLead
  | UpdateLeadSuccess
  | UpdateLeadFailure
  | UpdateFilterLeads
  | GetDuplicate
  | GetDuplicateSuccess
  | GetDuplicateFailure
  | GetActivities
  | GetActivitiesSuccess
  | GetActivitiesFailure
  | CreateActivity
  | CreateActivitySuccess
  | CreateActivityFailure
  | UpdateActivity
  | UpdateActivitySuccess
  | UpdateActivityFailure
  | CreateLead
  | CreateLeadSuccess
  | CreateLeadFailure
  | GetLeadsSuggestionSuccess
  | GetLeadsSuggestionFailure
  | GetLog
  | GetLogSuccess
  | GetLogFailure
  | ConvertToLead
  | ConvertToLeadSuccess
  | ConvertToLeadFailure
  | LeadAssigns
  | LeadAssignsSuccess
  | LeadAssignsFailure
  | ImportLeads
  | ImportLeadsSuccess
  | ImportLeadsFailure;