import { COLUMNS_CONSUMER } from "./constants/consumer.constant";
import { COLUMNS_LEAD } from "./constants/leads.constant";
import { COLUMNS_CONTACT } from "./constants/contact.constant";
import { COLUMNS_OPPORTUNITY } from "./constants/opportunity.constant";

export let APP_VERSION = 'crm-dev_version';
export let ACCESS_TOKEN_NAME = 'crm-dev_token';
export let REFRESH_TOKEN_NAME = 'crm-dev_refresh';
export let USER_INFO_NAME = 'crm-dev_user';

export const setVersionApp = data => {
  localStorage.setItem(APP_VERSION, data);
};

export const removeVersionApp = () => {
  localStorage.removeItem(APP_VERSION);
};

export const getVersionApp = () => {
  return localStorage.getItem(APP_VERSION) || null;
};

export const setUserToken = data => {
  localStorage.setItem(ACCESS_TOKEN_NAME, data.access_token);
  localStorage.setItem(REFRESH_TOKEN_NAME, data.refresh_token);
};

export const setUserData = data => {
  localStorage.setItem(USER_INFO_NAME, JSON.stringify(data));
};

export const removeUserToken = () => {
  localStorage.removeItem(ACCESS_TOKEN_NAME);
  localStorage.removeItem(REFRESH_TOKEN_NAME);
};

export const removeUserData = () => {
  localStorage.removeItem(USER_INFO_NAME);
};

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN_NAME) || null;
};

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN_NAME) || null;
};

export const getUserInfo = () => {
  let user_info = localStorage.getItem(USER_INFO_NAME) || null;
  if (user_info) {
    return JSON.parse(user_info);
  }
  return null;
};

export const isLoggedIn = () => {
  let user_info = localStorage.getItem(USER_INFO_NAME) || null;
  let access_token = localStorage.getItem(ACCESS_TOKEN_NAME) || null;
  let refresh_token = localStorage.getItem(REFRESH_TOKEN_NAME) || null;
  return user_info && access_token && refresh_token ? true : false;
};

export const clearAll = () => {
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then((names) => {
      for (const name of names) {
        caches.delete(name);
      }
    });
  }
  localStorage.clear();
};

const KEYS = {
  columns_leads: "crm-columns-leads",
  columns_consumers: "crm-columns-consumers",
  columns_contacts: "crm-columns-contacts",
  columns_opportunities: "crm-columns-opportunities",
};

export function getLeadsColumns() {
  if (localStorage.getItem(KEYS.columns_leads) == null) {
    const data = [];
    COLUMNS_LEAD.forEach((element, x) => {
      data.push({
        ...element,
        key: x
      });
    });
    localStorage.setItem(KEYS.columns_leads, JSON.stringify(data));
  }
  let result = []
  result = JSON.parse(localStorage.getItem(KEYS.columns_leads));
  return result
}

export function updateLeadsColumn(items, data) {
  let columns = items;
  let recordIndex = columns.findIndex((x) => x.key == data.key);
  columns[recordIndex] = { ...data };
  // localStorage.setItem(KEYS.columns_leads, JSON.stringify(columns));
  return columns
}

export function saveLeadsColumn(items) {
  return localStorage.setItem(KEYS.columns_leads, JSON.stringify(items));
}

export function resetLeadsColumn() {
  localStorage.removeItem(KEYS.columns_leads);
  const data = [];
  COLUMNS_LEAD.forEach((element, x) => {
    data.push({
      ...element,
      key: x
    });
  });
  localStorage.setItem(KEYS.columns_leads, JSON.stringify(data));
  return data
}
export function getConsumersColumns() {
  if (localStorage.getItem(KEYS.columns_consumers) == null) {
    const data = [];
    COLUMNS_CONSUMER.forEach((element,x) => {
      data.push({
        ...element,
        key: x
      });
    });
    localStorage.setItem(KEYS.columns_consumers, JSON.stringify(data));
  }
  let result = []
  result = JSON.parse(localStorage.getItem(KEYS.columns_consumers));
  return result
}
export function getContactsColumns() {
  if (localStorage.getItem(KEYS.columns_contacts) == null) {
    const data = [];
    COLUMNS_CONTACT.forEach((element,x) => {
      data.push({
        ...element,
        key: x
      });
    });
    localStorage.setItem(KEYS.columns_contacts, JSON.stringify(data));
  }
  let result = []
  result = JSON.parse(localStorage.getItem(KEYS.columns_contacts));
  return result
}
export function getOpportunitiesColumns() {
  if (localStorage.getItem(KEYS.columns_opportunities) == null) {
    const data = [];
    COLUMNS_OPPORTUNITY.forEach((element,x) => {
      data.push({
        ...element,
        key: x
      });
    });
    localStorage.setItem(KEYS.columns_opportunities, JSON.stringify(data));
  }
  let result = []
  result = JSON.parse(localStorage.getItem(KEYS.columns_opportunities));
  return result
}
export function updateConsumersColumn(items, data) {
  let columns = items;
  let recordIndex = columns.findIndex((x) => x.key == data.key);
  columns[recordIndex] = { ...data };
  // localStorage.setItem(KEYS.columns_Consumers, JSON.stringify(columns));
  return columns
}

export function saveConsumersColumn(items) {
  return localStorage.setItem(KEYS.columns_consumers, JSON.stringify(items));
}

export function resetConsumersColumn() {
  localStorage.removeItem(KEYS.columns_consumers);
  const data = [];
  COLUMNS_CONSUMER.forEach(element => {
    data.push(element);
  });
  localStorage.setItem(KEYS.columns_consumers, JSON.stringify(data));
  return data
}

export function updateContactsColumn(items, data) {
  let columns = items;
  let recordIndex = columns.findIndex((x) => x.key == data.key);
  columns[recordIndex] = { ...data };
  return columns
}

export function updateOpportunitiesColumn(items, data) {
  let columns = items;
  let recordIndex = columns.findIndex((x) => x.key == data.key);
  columns[recordIndex] = { ...data };
  return columns
}

export function saveContactsColumn(items) {
  return localStorage.setItem(KEYS.columns_contacts, JSON.stringify(items));
}

export function saveOpportunitiesColumn(items) {
  return localStorage.setItem(KEYS.columns_opportunities, JSON.stringify(items));
}

export function resetContactsColumn() {
  localStorage.removeItem(KEYS.columns_contacts);
  const data = [];
  COLUMNS_CONTACT.forEach(element => {
    data.push(element);
  });
  localStorage.setItem(KEYS.columns_contacts, JSON.stringify(data));
  return data
}

export function resetOpportunitiesColumn() {
  localStorage.removeItem(KEYS.columns_opportunities);
  const data = [];
  COLUMNS_OPPORTUNITY.forEach(element => {
    data.push(element);
  });
  localStorage.setItem(KEYS.columns_opportunities, JSON.stringify(data));
  return data
}

export function can(userPermissions, permission) {
  return userPermissions && (userPermissions.find(x => x.route_name === permission) ? true : false);
};