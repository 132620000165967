import ApiClient from 'src/utils/api-client';

// Permission Service
const getSettingsPermissionsService = payload => ApiClient.get(`/user-preference/permission`, payload, null).then(result => result?.data);
const getSettingsPermissionService = id => ApiClient.get(`/user-preference/permission/${id}`, null, null).then(result => result?.data);
const createSettingsPermissionService = payload =>
  ApiClient.post(`/user-preference/permission/`, payload, null).then(result => result?.data);
const updateSettingsPermissionService = (payload: any, id: any) =>
  ApiClient.put(`/user-preference/permission/${id}`, payload, null).then(result => result?.data);
const deleteSettingsPermissionService = (id: any) =>
  ApiClient.delete(`/user-preference/permission/${id}`, null).then(result => result?.data);

// Role Service
const getSettingsRolesService = payload => ApiClient.get(`/user-preference/role`, payload, null).then(result => result?.data);
const getSettingRoleDetail = id => ApiClient.get(`/user-preference/role/edit/${id}`, null, null).then(result => result?.data);
const createSettingsRoleService = payload => ApiClient.post(`/user-preference/role/`, payload, null).then(result => result?.data);
const updateSettingsRoleService = (payload: any, id: any) =>
  ApiClient.put(`/user-preference/role/${id}`, payload, null).then(result => result?.data);
const deleteSettingsRoleService = (id: any) => ApiClient.delete(`/user-preference/role/${id}`, null).then(result => result?.data);
const getPermissionOnRole = (payload: any, id: any) =>
  ApiClient.get(`/user-preference/role/permission/${id}`, payload, null).then(result => result?.data);
const updatePermissionOnRole = (payload: any, id: any) =>
  ApiClient.put(`/user-preference/role/permission/${id}`, payload, null).then(result => result?.data);
const getRoleOptions = () => ApiClient.get(`/user-preference/role/options`, null, null).then(result => result?.data);

// Menu Service
const getSettingsMenusService = () => ApiClient.get(`/user-preference/menu`, null, null).then(result => result?.data);
const getSettingsMenuService = id => ApiClient.get(`/user-preference/menu/${id}`, null, null).then(result => result?.data);
const createSettingsMenuService = payload => ApiClient.post(`/user-preference/menu/`, payload, null).then(result => result?.data);
const updateSettingsMenuService = (payload: any, id: any) =>
  ApiClient.put(`/user-preference/menu/${id}`, payload, null).then(result => result?.data);
const deleteSettingsMenuService = (id: any) => ApiClient.delete(`/user-preference/menu/${id}`, null).then(result => result?.data);
const orderingSettingsMenuService = payload => ApiClient.post(`/user-preference/menu/ordering`, payload, null).then(result => result?.data);

// User Service
const getSettingsUsersService = payload => ApiClient.get(`/user-preference/user`, payload, null).then(result => result?.data);
const getSettingsUserService = id => ApiClient.get(`/user-preference/user/${id}`, null, null).then(result => result?.data);
const createSettingsUserService = payload => ApiClient.post(`/user-preference/user/`, payload, null).then(result => result?.data);
const updateSettingsUserService = (payload: any, id: any) =>
  ApiClient.put(`/user-preference/user/${id}`, payload, null).then(result => result?.data);
const deleteSettingsUserService = (id: any) => ApiClient.delete(`/user-preference/user/${id}`, null).then(result => result?.data);
const getUserOptions = payload => ApiClient.get(`/user-preference/user/dropdown`, payload, null).then(result => result?.data);

const getNotificationsService = () =>
  ApiClient.withAuth().then(api =>
    api.get(`/user-preference/notification/?page=1&page_size=20`, {}, {}).then(result => result?.data),
  );

const notificationMarkAsDoneService = (id) =>
  ApiClient.withAuth().then(api =>
    api.put(`/user-preference/notification/mark-as-read/${id}`, {}, {}).then(result => result?.data),
  );

const notificationMarkAllAsDoneService = () =>
  ApiClient.withAuth().then(api =>
    api.put(`/user-preference/notification/mark-all-as-read`, {}, {}).then(result => result?.data),
  );

export {
  getSettingsPermissionsService,
  getSettingsPermissionService,
  createSettingsPermissionService,
  updateSettingsPermissionService,
  deleteSettingsPermissionService,
  getSettingsRolesService,
  getSettingRoleDetail,
  createSettingsRoleService,
  updateSettingsRoleService,
  deleteSettingsRoleService,
  getPermissionOnRole,
  updatePermissionOnRole,
  getSettingsMenusService,
  getSettingsMenuService,
  createSettingsMenuService,
  updateSettingsMenuService,
  deleteSettingsMenuService,
  orderingSettingsMenuService,
  getSettingsUsersService,
  getSettingsUserService,
  createSettingsUserService,
  updateSettingsUserService,
  deleteSettingsUserService,
  getRoleOptions,
  getUserOptions,
  getNotificationsService,
  notificationMarkAsDoneService,
  notificationMarkAllAsDoneService
};
