import axios from 'axios';
import { endpoint } from '../config/env';
import { IS_LOCAL } from './constants/global.constants';
import { clearAll, getAccessToken } from './storage';


const Client = axios.create({
  /**
   * Import the config from the src/config/env.ts file
   */
  baseURL: `${endpoint.baseUrl}/${endpoint.version}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

Client.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      if (error.response.status === 401) {
        clearAll();
        window.location.href = '/login';
      }
    }
    return Promise.reject(error?.response ? error?.response?.data : error);
  },
);

const getIntance = (url, params = {}, config = {}) =>
  Client.get(url, {
    params,
    ...config,
  });

const postIntance = (url, data, config) =>
  Client.post(url, data, {
    ...config,
  });

const putIntance = (url, data, config) =>
  Client.put(url, data, {
    ...config,
  });

const patchIntance = (url, data, config) =>
  Client.patch(url, data, {
    ...config,
  });

const deleteIntance = (url, config) =>
  Client.delete(url, {
    ...config,
  });

const withAuth = async () => {
  const session = await getAccessToken();
  const token = session ? `Bearer ${session}` : null;

  const getConfig = config => {
    let conf = {
      ...config,
      headers: {
        ...config?.headers,
        Authorization: token,
      },
    }
    window.location.href.split('/')[2].substring(0, window.location.href.split('/')[2].indexOf(":")) != IS_LOCAL && (
      delete conf.headers.Authorization
    )
    return conf
  }

  return {
    get: (url, params, config) => getIntance(url, params, getConfig(config)),
    post: (url, data, config) => postIntance(url, data, getConfig(config)),
    put: (url, data, config) => putIntance(url, data, getConfig(config)),
    patch: (url, data, config) => patchIntance(url, data, getConfig(config)),
    delete: (url, config) => deleteIntance(url, getConfig(config)),
  };
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get: getIntance,
  post: postIntance,
  put: putIntance,
  patch: patchIntance,
  delete: deleteIntance,
  withAuth,
};
