export const GET_CONSUMERS = 'GET_CONSUMERS';
export const GET_CONSUMERS_SUCCESS = 'GET_CONSUMERS_SUCCESS';
export const GET_CONSUMERS_FAILURE = 'GET_CONSUMERS_FAILURE';
export const GET_CONSUMER_FILTER = 'GET_CONSUMER_FILTER';
export const GET_CONSUMER_SEARCH_SUGGESTION_SUCCESS = 'GET_CONSUMER_SEARCH_SUGGESTION_SUCCESS';
export const GET_CONSUMER_SEARCH_SUGGESTION_FAILURE = 'GET_CONSUMER_SEARCH_SUGGESTION_FAILURE';
export type ConsumersType = {
  data: any;
};
export interface GetConsumers {
  type: typeof GET_CONSUMERS;
}
export interface GetConsumersSuccess {
  type: typeof GET_CONSUMERS_SUCCESS;
  payload: ConsumersType;
}
export interface GetConsumersFailure {
  type: typeof GET_CONSUMERS_FAILURE;
  message: string;
}

export interface GetConsumerFilter {
  type: typeof GET_CONSUMER_FILTER;
  payload: any;
}

export interface GetConsumerSearchSuggestionSuccess {
  type: typeof GET_CONSUMER_SEARCH_SUGGESTION_SUCCESS;
  payload: ConsumersType;
}

export interface GetConsumerSearchSuggestionFailure {
  type: typeof GET_CONSUMER_SEARCH_SUGGESTION_FAILURE;
}

export type ConsumersDispatchTypes =
  | GetConsumers
  | GetConsumersSuccess
  | GetConsumersFailure
  | GetConsumerFilter
  | GetConsumerSearchSuggestionSuccess
  | GetConsumerSearchSuggestionFailure;
