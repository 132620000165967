export const GET_SETTINGS_PERMISSIONS = 'GET_SETTINGS_PERMISSIONS';
export const GET_SETTINGS_PERMISSIONS_SUCCESS = 'GET_SETTINGS_PERMISSIONS_SUCCESS';
export const GET_SETTINGS_PERMISSIONS_FAILURE = 'GET_SETTINGS_PERMISSIONS_FAILURE';
export const GET_SETTINGS_PERMISSION = 'GET_SETTINGS_PERMISSION';
export const GET_SETTINGS_PERMISSION_SUCCESS = 'GET_SETTINGS_PERMISSION_SUCCESS';
export const GET_SETTINGS_PERMISSION_FAILURE = 'GET_SETTINGS_PERMISSION_FAILURE';
export const CREATE_SETTINGS_PERMISSION = 'CREATE_SETTINGS_PERMISSION';
export const CREATE_SETTINGS_PERMISSION_SUCCESS = 'CREATE_SETTINGS_PERMISSION_SUCCESS';
export const CREATE_SETTINGS_PERMISSION_FAILURE = 'CREATE_SETTINGS_PERMISSION_FAILURE';
export const UPDATE_SETTINGS_PERMISSION = 'UPDATE_SETTINGS_PERMISSION';
export const UPDATE_SETTINGS_PERMISSION_SUCCESS = 'UPDATE_SETTINGS_PERMISSION_SUCCESS';
export const UPDATE_SETTINGS_PERMISSION_FAILURE = 'UPDATE_SETTINGS_PERMISSION_FAILURE';
export const DELETE_SETTINGS_PERMISSION = 'DELETE_SETTINGS_PERMISSION';
export const DELETE_SETTINGS_PERMISSION_SUCCESS = 'DELETE_SETTINGS_PERMISSION_SUCCESS';
export const DELETE_SETTINGS_PERMISSION_FAILURE = 'DELETE_SETTINGS_PERMISSION_FAILURE';
export const GET_SETTINGS_ROLES = 'GET_SETTINGS_ROLES';
export const GET_SETTINGS_ROLES_SUCCESS = 'GET_SETTINGS_ROLES_SUCCESS';
export const GET_SETTINGS_ROLES_FAILURE = 'GET_SETTINGS_ROLES_FAILURE';
export const GET_SETTINGS_ROLE = 'GET_SETTINGS_ROLE';
export const GET_SETTINGS_ROLE_SUCCESS = 'GET_SETTINGS_ROLE_SUCCESS';
export const GET_SETTINGS_ROLE_FAILURE = 'GET_SETTINGS_ROLE_FAILURE';
export const CREATE_SETTINGS_ROLE = 'CREATE_SETTINGS_ROLE';
export const CREATE_SETTINGS_ROLE_SUCCESS = 'CREATE_SETTINGS_ROLE_SUCCESS';
export const CREATE_SETTINGS_ROLE_FAILURE = 'CREATE_SETTINGS_ROLE_FAILURE';
export const UPDATE_SETTINGS_ROLE = 'UPDATE_SETTINGS_ROLE';
export const UPDATE_SETTINGS_ROLE_SUCCESS = 'UPDATE_SETTINGS_ROLE_SUCCESS';
export const UPDATE_SETTINGS_ROLE_FAILURE = 'UPDATE_SETTINGS_ROLE_FAILURE';
export const DELETE_SETTINGS_ROLE = 'DELETE_SETTINGS_ROLE';
export const DELETE_SETTINGS_ROLE_SUCCESS = 'DELETE_SETTINGS_ROLE_SUCCESS';
export const DELETE_SETTINGS_ROLE_FAILURE = 'DELETE_SETTINGS_ROLE_FAILURE';
export const GET_SETTINGS_ROLE_PERMISSION = 'GET_SETTINGS_ROLE_PERMISSION';
export const GET_SETTINGS_ROLE_PERMISSION_SUCCESS = 'GET_SETTINGS_ROLE_PERMISSION_SUCCESS';
export const GET_SETTINGS_ROLE_PERMISSION_FAILURE = 'GET_SETTINGS_ROLE_PERMISSION_FAILURE';
export const UPDATE_SETTINGS_ROLE_PERMISSION = 'UPDATE_SETTINGS_ROLE_PERMISSION';
export const UPDATE_SETTINGS_ROLE_PERMISSION_SUCCESS = 'UPDATE_SETTINGS_ROLE_PERMISSION_SUCCESS';
export const UPDATE_SETTINGS_ROLE_PERMISSION_FAILURE = 'UPDATE_SETTINGS_ROLE_PERMISSION_FAILURE';
export const GET_SETTINGS_MENUS = 'GET_SETTINGS_MENUS';
export const GET_SETTINGS_MENUS_SUCCESS = 'GET_SETTINGS_MENUS_SUCCESS';
export const GET_SETTINGS_MENUS_FAILURE = 'GET_SETTINGS_MENUS_FAILURE';
export const GET_SETTINGS_MENU = 'GET_SETTINGS_MENU';
export const GET_SETTINGS_MENU_SUCCESS = 'GET_SETTINGS_MENU_SUCCESS';
export const GET_SETTINGS_MENU_FAILURE = 'GET_SETTINGS_MENU_FAILURE';
export const CREATE_SETTINGS_MENU = 'CREATE_SETTINGS_MENU';
export const CREATE_SETTINGS_MENU_SUCCESS = 'CREATE_SETTINGS_MENU_SUCCESS';
export const CREATE_SETTINGS_MENU_FAILURE = 'CREATE_SETTINGS_MENU_FAILURE';
export const UPDATE_SETTINGS_MENU = 'UPDATE_SETTINGS_MENU';
export const UPDATE_SETTINGS_MENU_SUCCESS = 'UPDATE_SETTINGS_MENU_SUCCESS';
export const UPDATE_SETTINGS_MENU_FAILURE = 'UPDATE_SETTINGS_MENU_FAILURE';
export const DELETE_SETTINGS_MENU = 'DELETE_SETTINGS_MENU';
export const DELETE_SETTINGS_MENU_SUCCESS = 'DELETE_SETTINGS_MENU_SUCCESS';
export const DELETE_SETTINGS_MENU_FAILURE = 'DELETE_SETTINGS_MENU_FAILURE';
export const ORDERING_SETTINGS_MENU = 'ORDERING_SETTINGS_MENU';
export const ORDERING_SETTINGS_MENU_SUCCESS = 'ORDERING_SETTINGS_MENU_SUCCESS';
export const ORDERING_SETTINGS_MENU_FAILURE = 'ORDERING_SETTINGS_MENU_FAILURE';
export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';
export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
export const OPTION_USERS = 'OPTION_USERS';
export const OPTION_USERS_SUCCESS = 'OPTION_USERS_SUCCESS';
export const OPTION_USERS_FAILURE = 'OPTION_USERS_FAILURE';
export const OPTION_ROLES = 'OPTION_ROLES';
export const OPTION_ROLES_SUCCESS = 'OPTION_ROLES_SUCCESS';
export const OPTION_ROLES_FAILURE = 'OPTION_ROLES_FAILURE';

export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_FAILURE = 'GET_NOTIFICATION_FAILURE';
export const MARK_AS_READ = 'MARK_AS_READ';
export const MARK_AS_READ_SUCCESS = 'MARK_AS_READ_SUCCESS';
export const MARK_AS_READ_FAILURE = 'MARK_AS_READ_FAILURE';

export type SettingsUsersType = {
  data: any;
};

export type SettingsPermissionsType = {
  data: any;
};

export type SettingsRolesType = {
  data: any;
};

export type SettingsRolesPermissionType = {
  data: any;
};

export type SettingsMenusType = {
  data: any;
};

export type OptionUsersType = {
  data: any;
};

export type OptionRolesType = {
  data: any;
};
export interface GetSettingsPermissions {
  type: typeof GET_SETTINGS_PERMISSIONS;
}
export interface GetSettingsPermissionsSuccess {
  type: typeof GET_SETTINGS_PERMISSIONS_SUCCESS;
  payload: SettingsPermissionsType;
}
export interface GetSettingsPermission {
  type: typeof GET_SETTINGS_PERMISSION;
}
export interface GetSettingsPermissionSuccess {
  type: typeof GET_SETTINGS_PERMISSION_SUCCESS;
  payload: any;
}
export interface GetSettingsPermissionFailure {
  type: typeof GET_SETTINGS_PERMISSION_FAILURE;
  message: any;
}
export interface CreatePermission {
  type: typeof CREATE_SETTINGS_PERMISSION;
}

export interface CreatePermissionSuccess {
  type: typeof CREATE_SETTINGS_PERMISSION_SUCCESS;
  payload: any;
}
export interface CreatePermissionFailure {
  type: typeof CREATE_SETTINGS_PERMISSION_FAILURE;
  message: any;
}
export interface UpdatePermission {
  type: typeof UPDATE_SETTINGS_PERMISSION;
}
export interface UpdatePermissionSuccess {
  type: typeof UPDATE_SETTINGS_PERMISSION_SUCCESS;
  payload: any;
}
export interface UpdatePermissionFailure {
  type: typeof UPDATE_SETTINGS_PERMISSION_FAILURE;
  message: any;
}
export interface DeletePermission {
  type: typeof DELETE_SETTINGS_PERMISSION;
}
export interface DeletePermissionSuccess {
  type: typeof DELETE_SETTINGS_PERMISSION_SUCCESS;
  message: any;
}
export interface DeletePermissionFailure {
  type: typeof DELETE_SETTINGS_PERMISSION_FAILURE;
  message: any;
}
export interface GetSettingsPermissionsFailure {
  type: typeof GET_SETTINGS_PERMISSIONS_FAILURE;
}
export interface GetSettingsRoles {
  type: typeof GET_SETTINGS_ROLES;
}
export interface GetSettingsRolesSuccess {
  type: typeof GET_SETTINGS_ROLES_SUCCESS;
  payload: SettingsRolesType;
}
export interface GetSettingsRolesFailure {
  type: typeof GET_SETTINGS_ROLES_FAILURE;
}
export interface GetSettingsRole {
  type: typeof GET_SETTINGS_ROLE;
}
export interface GetSettingsRoleSuccess {
  type: typeof GET_SETTINGS_ROLE_SUCCESS;
  payload: any;
}
export interface GetSettingsRoleFailure {
  type: typeof GET_SETTINGS_ROLE_FAILURE;
  message: any;
}
export interface CreateRole {
  type: typeof CREATE_SETTINGS_ROLE;
}
export interface CreateRoleSuccess {
  type: typeof CREATE_SETTINGS_ROLE_SUCCESS;
  payload: any;
}
export interface CreateRoleFailure {
  type: typeof CREATE_SETTINGS_ROLE_FAILURE;
  message: any;
}
export interface UpdateRole {
  type: typeof UPDATE_SETTINGS_ROLE;
}
export interface UpdateRoleSuccess {
  type: typeof UPDATE_SETTINGS_ROLE_SUCCESS;
  payload: any;
}
export interface UpdateRoleFailure {
  type: typeof UPDATE_SETTINGS_ROLE_FAILURE;
  message: any;
}
export interface DeleteRole {
  type: typeof DELETE_SETTINGS_ROLE;
}
export interface DeleteRoleSuccess {
  type: typeof DELETE_SETTINGS_ROLE_SUCCESS;
  message: any;
}
export interface DeleteRoleFailure {
  type: typeof DELETE_SETTINGS_ROLE_FAILURE;
  message: any;
}
export interface GetSettingsRoleFailure {
  type: typeof GET_SETTINGS_ROLE_FAILURE;
}
export interface GetSettingsRolePermission {
  type: typeof GET_SETTINGS_ROLE_PERMISSION;
}
export interface GetSettingsRolePermissionSuccess {
  type: typeof GET_SETTINGS_ROLE_PERMISSION_SUCCESS;
  payload: SettingsRolesPermissionType;
}
export interface GetSettingsRolePermissionFailure {
  type: typeof GET_SETTINGS_ROLE_PERMISSION_FAILURE;
}
export interface UpdateRolePermission {
  type: typeof UPDATE_SETTINGS_ROLE_PERMISSION;
}
export interface UpdateRolePermissionSuccess {
  type: typeof UPDATE_SETTINGS_ROLE_PERMISSION_SUCCESS;
  payload: any;
}
export interface UpdateRolePermissionFailure {
  type: typeof UPDATE_SETTINGS_ROLE_PERMISSION_FAILURE;
  message: any;
}

export interface GetSettingsMenus {
  type: typeof GET_SETTINGS_MENUS;
}
export interface GetSettingsMenusSuccess {
  type: typeof GET_SETTINGS_MENUS_SUCCESS;
  payload: SettingsMenusType;
}
export interface GetSettingsMenusFailure {
  type: typeof GET_SETTINGS_MENUS_FAILURE;
}
export interface GetSettingsMenu {
  type: typeof GET_SETTINGS_MENU;
}
export interface GetSettingsMenuSuccess {
  type: typeof GET_SETTINGS_MENU_SUCCESS;
  payload: any;
}
export interface GetSettingsMenuFailure {
  type: typeof GET_SETTINGS_MENU_FAILURE;
  message: any;
}
export interface GetSettingsMenu {
  type: typeof GET_SETTINGS_MENU;
}
export interface GetSettingsMenuSuccess {
  type: typeof GET_SETTINGS_MENU_SUCCESS;
  payload: any;
}
export interface GetSettingsMenuFailure {
  type: typeof GET_SETTINGS_MENU_FAILURE;
  message: any;
}
export interface CreateMenu {
  type: typeof CREATE_SETTINGS_MENU;
}
export interface CreateMenuSuccess {
  type: typeof CREATE_SETTINGS_MENU_SUCCESS;
  payload: any;
}
export interface CreateMenuFailure {
  type: typeof CREATE_SETTINGS_MENU_FAILURE;
  message: any;
}
export interface UpdateMenu {
  type: typeof UPDATE_SETTINGS_MENU;
}
export interface UpdateMenuSuccess {
  type: typeof UPDATE_SETTINGS_MENU_SUCCESS;
  payload: any;
}
export interface UpdateMenuFailure {
  type: typeof UPDATE_SETTINGS_MENU_FAILURE;
  message: any;
}
export interface DeleteMenu {
  type: typeof DELETE_SETTINGS_MENU;
}
export interface DeleteMenuSuccess {
  type: typeof DELETE_SETTINGS_MENU_SUCCESS;
  message: any;
}
export interface DeleteMenuFailure {
  type: typeof DELETE_SETTINGS_MENU_FAILURE;
  message: any;
}
export interface OrderingMenu {
  type: typeof ORDERING_SETTINGS_MENU;
}
export interface OrderingMenuSuccess {
  type: typeof ORDERING_SETTINGS_MENU_SUCCESS;
}
export interface OrderingMenuFailure {
  type: typeof ORDERING_SETTINGS_MENU_FAILURE;
  message: any;
}
export interface GetUsers {
  type: typeof GET_USERS;
}
export interface GetUsersSuccess {
  type: typeof GET_USERS_SUCCESS;
  payload: SettingsUsersType;
}
export interface GetUsersFailure {
  type: typeof GET_USERS_FAILURE;
}
export interface GetUser {
  type: typeof GET_USER;
}
export interface GetUserSuccess {
  type: typeof GET_USER_SUCCESS;
  payload: any;
}
export interface GetUserFailure {
  type: typeof GET_USER_FAILURE;
  message: any;
}
export interface CreateUser {
  type: typeof CREATE_USER;
}
export interface CreateUserSuccess {
  type: typeof CREATE_USER_SUCCESS;
  payload: any;
}
export interface CreateUserFailure {
  type: typeof CREATE_USER_FAILURE;
  message: any;
}
export interface UpdateUser {
  type: typeof UPDATE_USER;
}
export interface UpdateUserSuccess {
  type: typeof UPDATE_USER_SUCCESS;
  payload: any;
}
export interface UpdateUserFailure {
  type: typeof UPDATE_USER_FAILURE;
  message: any;
}
export interface DeleteUser {
  type: typeof DELETE_USER;
}
export interface DeleteUserSuccess {
  type: typeof DELETE_USER_SUCCESS;
  message: any;
}
export interface DeleteUserFailure {
  type: typeof DELETE_USER_FAILURE;
  message: any;
}
export interface OptionUsers {
  type: typeof OPTION_USERS;
}
export interface OptionUsersSuccess {
  type: typeof OPTION_USERS_SUCCESS;
  payload: OptionUsersType;
}
export interface OptionUsersFailure {
  type: typeof OPTION_USERS_FAILURE;
}

export interface OptionRoles {
  type: typeof OPTION_ROLES;
}
export interface OptionRolesSuccess {
  type: typeof OPTION_ROLES_SUCCESS;
  payload: OptionRolesType;
}
export interface OptionRolesFailure {
  type: typeof OPTION_ROLES_FAILURE;
}
export interface GetNotification {
  type: typeof GET_NOTIFICATION;
}

export interface GetNotificationSuccess {
  type: typeof GET_NOTIFICATION_SUCCESS;
  payload: any;
  total: any;
}

export interface GetNotificationFailure {
  type: typeof GET_NOTIFICATION_FAILURE;
}
export interface MarkAsRead {
  type: typeof MARK_AS_READ;
}
export interface MarkAsReadSuccess {
  type: typeof MARK_AS_READ_SUCCESS;
  payload: any;
}
export interface MarkAsReadFailure {
  type: typeof MARK_AS_READ_FAILURE;
}

export type SettingsDispatchTypes =
  | GetSettingsPermissions
  | GetSettingsPermissionsSuccess
  | GetSettingsPermissionsFailure
  | GetSettingsPermission
  | GetSettingsPermissionSuccess
  | GetSettingsPermissionFailure
  | CreatePermission
  | CreatePermissionSuccess
  | CreatePermissionFailure
  | UpdatePermission
  | UpdatePermissionSuccess
  | UpdatePermissionFailure
  | DeletePermission
  | DeletePermissionSuccess
  | DeletePermissionFailure
  | GetSettingsRoles
  | GetSettingsRolesSuccess
  | GetSettingsRolesFailure
  | GetSettingsRole
  | GetSettingsRoleSuccess
  | GetSettingsRoleFailure
  | CreateRole
  | CreateRoleSuccess
  | CreateRoleFailure
  | UpdateRole
  | UpdateRoleSuccess
  | UpdateRoleFailure
  | UpdateRolePermission
  | UpdateRolePermissionSuccess
  | UpdateRolePermissionFailure
  | GetSettingsRolePermission
  | GetSettingsRolePermissionSuccess
  | GetSettingsRolePermissionFailure
  | DeleteRole
  | DeleteRoleSuccess
  | DeleteRoleFailure
  | GetSettingsMenus
  | GetSettingsMenusSuccess
  | GetSettingsMenusFailure
  | GetSettingsMenu
  | GetSettingsMenuSuccess
  | GetSettingsMenuFailure
  | CreateMenu
  | CreateMenuSuccess
  | CreateMenuFailure
  | UpdateMenu
  | UpdateMenuSuccess
  | UpdateMenuFailure
  | DeleteMenu
  | DeleteMenuSuccess
  | DeleteMenuFailure
  | OrderingMenu
  | OrderingMenuSuccess
  | OrderingMenuFailure
  | GetUsers
  | GetUsersSuccess
  | GetUsersFailure
  | GetUser
  | GetUserSuccess
  | GetUserFailure
  | CreateUser
  | CreateUserSuccess
  | CreateUserFailure
  | UpdateUser
  | UpdateUserSuccess
  | UpdateUserFailure
  | DeleteUser
  | DeleteUserSuccess
  | DeleteUserFailure
  | OptionUsers
  | OptionUsersSuccess
  | OptionUsersFailure
  | OptionRoles
  | OptionRolesSuccess
  | OptionRolesFailure
  | GetNotification
  | GetNotificationSuccess
  | GetNotificationFailure
  | MarkAsRead
  | MarkAsReadSuccess
  | MarkAsReadFailure;