import {
  ContactsType,
  ContactsDispatchTypes,
  GET_CONTACTS,
  GET_CONTACTS_SUCCESS,
  GET_CONTACTS_FAILURE,
  GET_CONTACTS_FILTER,
} from './contacts-types';

interface DefaultStateI {
  loading: boolean;
  error?: boolean;
  messages?: any;

  contacts: ContactsType;
  filterQuery: any;
}

const defaultState: DefaultStateI = {
  loading: false,
  contacts: {
    data: [],
  },
  filterQuery: {},
};

const contactsReducer = (state: DefaultStateI = defaultState, action: ContactsDispatchTypes): DefaultStateI => {
  switch (action.type) {
    case GET_CONTACTS:
      return {
        ...state,
        loading: true,
      };
    case GET_CONTACTS_SUCCESS:
      return {
        ...state,
        loading: false,
        contacts: action.payload,
      };
    case GET_CONTACTS_FAILURE:
      return {
        ...state,
        loading: false,
        messages: action.message,
      };
    case GET_CONTACTS_FILTER:
      return {
        ...state,
        filterQuery: action.payload,
      };
    default:
      return state;
  }
};

export default contactsReducer;
