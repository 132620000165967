import {
  GET_SETTINGS_PERMISSIONS,
  GET_SETTINGS_PERMISSIONS_SUCCESS,
  GET_SETTINGS_PERMISSIONS_FAILURE,
  SettingsDispatchTypes,
  GET_SETTINGS_ROLES,
  GET_SETTINGS_ROLES_SUCCESS,
  GET_SETTINGS_ROLES_FAILURE,
  GET_SETTINGS_ROLE,
  GET_SETTINGS_ROLE_SUCCESS,
  GET_SETTINGS_ROLE_FAILURE,
  GET_SETTINGS_ROLE_PERMISSION,
  GET_SETTINGS_ROLE_PERMISSION_SUCCESS,
  GET_SETTINGS_ROLE_PERMISSION_FAILURE,
  UPDATE_SETTINGS_ROLE_PERMISSION,
  UPDATE_SETTINGS_ROLE_PERMISSION_SUCCESS,
  UPDATE_SETTINGS_ROLE_PERMISSION_FAILURE,
  GET_SETTINGS_PERMISSION,
  GET_SETTINGS_PERMISSION_SUCCESS,
  GET_SETTINGS_PERMISSION_FAILURE,
  CREATE_SETTINGS_PERMISSION,
  CREATE_SETTINGS_PERMISSION_SUCCESS,
  CREATE_SETTINGS_PERMISSION_FAILURE,
  DELETE_SETTINGS_PERMISSION,
  DELETE_SETTINGS_PERMISSION_SUCCESS,
  DELETE_SETTINGS_PERMISSION_FAILURE,
  UPDATE_SETTINGS_PERMISSION,
  UPDATE_SETTINGS_PERMISSION_SUCCESS,
  UPDATE_SETTINGS_PERMISSION_FAILURE,
  GET_SETTINGS_MENUS,
  GET_SETTINGS_MENUS_SUCCESS,
  GET_SETTINGS_MENUS_FAILURE,
  GET_SETTINGS_MENU,
  GET_SETTINGS_MENU_SUCCESS,
  GET_SETTINGS_MENU_FAILURE,
  CREATE_SETTINGS_MENU,
  CREATE_SETTINGS_MENU_SUCCESS,
  CREATE_SETTINGS_MENU_FAILURE,
  DELETE_SETTINGS_MENU,
  UPDATE_SETTINGS_MENU,
  UPDATE_SETTINGS_MENU_SUCCESS,
  UPDATE_SETTINGS_MENU_FAILURE,
  DELETE_SETTINGS_MENU_SUCCESS,
  DELETE_SETTINGS_MENU_FAILURE,
  ORDERING_SETTINGS_MENU,
  ORDERING_SETTINGS_MENU_SUCCESS,
  ORDERING_SETTINGS_MENU_FAILURE,
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  OPTION_USERS,
  OPTION_USERS_SUCCESS,
  OPTION_USERS_FAILURE,
  OPTION_ROLES,
  OPTION_ROLES_SUCCESS,
  OPTION_ROLES_FAILURE,
  GET_NOTIFICATION,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILURE,
  MARK_AS_READ,
  MARK_AS_READ_SUCCESS,
  MARK_AS_READ_FAILURE,
} from './settings-types';

interface DefaultStateI {
  loading: boolean;
  error?: boolean;
  messages?: any;

  permissions: {
    data: [];
  };
  loadingPermissions: boolean;
  errorPermissions?: boolean;
  roles: {
    data: [];
  };
  loadingRoles: boolean;
  errorRoles?: boolean;

  loadingGetRole: boolean;
  successGetRole: boolean;
  errorGetRole: boolean;
  role?: any;
  errorMessage: any;

  loadingGetPermission: boolean;
  successGetPermission: boolean;
  errorGetPermission: boolean;
  permission: any;

  loadingCreatePermission: boolean;
  successCreatePermission: boolean;
  errorCreatePermission: boolean;

  loadingUpdatePermission: boolean;
  successUpdatePermission: boolean;
  errorUpdatePermission: boolean;
  messageUpdatePermission: any;

  loadingDeletePermission: boolean;
  successDeletePermission: boolean;
  errorDeletePermission: boolean;
  messageDeletePermission: any;
  errorMessageDeletePermission: any;

  menus: {
    data: [];
  };
  loadingMenus: boolean;
  errorMenus?: boolean;

  loadingGetMenu: boolean;
  successGetMenu: boolean;
  errorGetMenu: boolean;
  menu: any;

  loadingCreateMenu: boolean;
  successCreateMenu: boolean;
  errorCreateMenu: boolean;

  loadingUpdateMenu: boolean;
  successUpdateMenu: boolean;
  errorUpdateMenu: boolean;
  messageUpdateMenu: any;

  loadingDeleteMenu: boolean;
  successDeleteMenu: boolean;
  errorDeleteMenu: boolean;
  messageDeleteMenu: any;
  errorMessageDeleteMenu: any;

  loadingOrderingMenu: boolean;
  successOrderingMenu: boolean;
  errorOrderingMenu: boolean;

  rolePermissions: {
    data: [];
  };

  loadingGetRolePermission: boolean;
  successGetRolePermission: boolean;
  errorGetRolePermission: boolean;
  messageGetRolePermission: any;
  errorMessageGetRolePermission: any;

  loadingUpdateRolePermission: boolean;
  successUpdateRolePermission: boolean;
  errorUpdateRolePermission: boolean;
  messageUpdateRolePermission: any;

  users: {
    data: [];
  };

  loadingUsers: boolean;
  errorUsers?: boolean;

  loadingGetUser: boolean;
  successGetUser: boolean;
  errorGetUser: boolean;
  user: any;

  loadingCreateUser: boolean;
  successCreateUser: boolean;
  errorCreateUser: boolean;

  loadingUpdateUser: boolean;
  successUpdateUser: boolean;
  errorUpdateUser: boolean;
  messageUpdateUser: any;

  loadingDeleteUser: boolean;
  successDeleteUser: boolean;
  errorDeleteUser: boolean;
  messageDeleteUser: any;
  errorMessageDeleteUser: any;

  optionUsers: {
    data: [];
  };

  loadingOptionUser: boolean;
  successOptionUser: boolean;
  errorOptionUser: boolean;

  optionRoles: {
    data: [];
  };

  loadingOptionRole: boolean;
  successOptionRole: boolean;
  errorOptionRole: boolean;

  loadingGetNotification: boolean,
  errorGetNotification: boolean,
  successGetNotification: boolean,
  notification: any,
  notificationTotal: any,
}

const defaultState: DefaultStateI = {
  loading: false,
  permissions: {
    data: [],
  },
  loadingPermissions: false,
  roles: {
    data: [],
  },
  loadingRoles: false,
  loadingGetRole: false,
  successGetRole: false,
  errorGetRole: false,
  role: {},
  errorMessage: {},

  loadingGetPermission: false,
  successGetPermission: false,
  errorGetPermission: false,
  permission: {},

  loadingCreatePermission: false,
  successCreatePermission: false,
  errorCreatePermission: false,

  loadingUpdatePermission: false,
  successUpdatePermission: false,
  errorUpdatePermission: false,
  messageUpdatePermission: {},

  loadingDeletePermission: false,
  successDeletePermission: false,
  errorDeletePermission: false,
  messageDeletePermission: {},
  errorMessageDeletePermission: {},

  menus: {
    data: [],
  },
  loadingMenus: false,

  loadingGetMenu: false,
  successGetMenu: false,
  errorGetMenu: false,
  menu: {},

  loadingCreateMenu: false,
  successCreateMenu: false,
  errorCreateMenu: false,

  loadingUpdateMenu: false,
  successUpdateMenu: false,
  errorUpdateMenu: false,
  messageUpdateMenu: {},

  loadingDeleteMenu: false,
  successDeleteMenu: false,
  errorDeleteMenu: false,
  messageDeleteMenu: {},
  errorMessageDeleteMenu: {},

  loadingOrderingMenu: false,
  successOrderingMenu: false,
  errorOrderingMenu: false,

  rolePermissions: {
    data: [],
  },

  loadingGetRolePermission: false,
  successGetRolePermission: false,
  errorGetRolePermission: false,
  messageGetRolePermission: {},
  errorMessageGetRolePermission: {},

  loadingUpdateRolePermission: false,
  successUpdateRolePermission: false,
  errorUpdateRolePermission: false,
  messageUpdateRolePermission: {},

  users: {
    data: [],
  },

  loadingUsers: false,
  errorUsers: false,

  loadingGetUser: false,
  successGetUser: false,
  errorGetUser: false,
  user: {},

  loadingCreateUser: false,
  successCreateUser: false,
  errorCreateUser: false,

  loadingUpdateUser: false,
  successUpdateUser: false,
  errorUpdateUser: false,
  messageUpdateUser: {},

  loadingDeleteUser: false,
  successDeleteUser: false,
  errorDeleteUser: false,
  messageDeleteUser: {},
  errorMessageDeleteUser: {},

  optionUsers: {
    data: [],
  },

  loadingOptionUser: false,
  successOptionUser: false,
  errorOptionUser: false,

  optionRoles: {
    data: [],
  },

  loadingOptionRole: false,
  successOptionRole: false,
  errorOptionRole: false,

  loadingGetNotification: false,
  errorGetNotification: false,
  successGetNotification: false,
  notification: [],
  notificationTotal: []
};

const settingsReducer = (state: DefaultStateI = defaultState, action: SettingsDispatchTypes): DefaultStateI => {
  switch (action.type) {
    case GET_SETTINGS_PERMISSIONS:
      return {
        ...state,
        loadingPermissions: true,
      };
    case GET_SETTINGS_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loadingPermissions: false,
        permissions: action.payload,
      };
    case GET_SETTINGS_PERMISSIONS_FAILURE:
      return {
        ...state,
        loadingPermissions: false,
      };
    case GET_SETTINGS_PERMISSION:
      return {
        ...state,
        loadingGetPermission: true,
        successGetPermission: false,
        errorGetPermission: false,
      };
    case GET_SETTINGS_PERMISSION_SUCCESS:
      return {
        ...state,
        loadingGetPermission: false,
        successGetPermission: true,
        errorGetPermission: false,
        permission: action.payload,
      };
    case GET_SETTINGS_PERMISSION_FAILURE:
      return {
        ...state,
        loadingGetPermission: false,
        successGetPermission: false,
        errorGetPermission: true,
        errorMessage: action.message,
      };
    case CREATE_SETTINGS_PERMISSION:
      return {
        ...state,
        loadingCreatePermission: true,
        successCreatePermission: false,
        errorCreatePermission: false,
      };
    case CREATE_SETTINGS_PERMISSION_SUCCESS:
      return {
        ...state,
        loadingCreatePermission: false,
        successCreatePermission: true,
        errorCreatePermission: false,
        permission: action.payload,
      };
    case CREATE_SETTINGS_PERMISSION_FAILURE:
      return {
        ...state,
        loadingCreatePermission: false,
        successCreatePermission: false,
        errorCreatePermission: true,
        errorMessage: action.message,
      };
    case UPDATE_SETTINGS_PERMISSION:
      return {
        ...state,
        loadingUpdatePermission: true,
        successUpdatePermission: false,
        errorUpdatePermission: false,
      };
    case UPDATE_SETTINGS_PERMISSION_SUCCESS:
      return {
        ...state,
        loadingUpdatePermission: false,
        successUpdatePermission: true,
        errorUpdatePermission: false,
        permission: action.payload,
      };
    case UPDATE_SETTINGS_PERMISSION_FAILURE:
      return {
        ...state,
        loadingUpdatePermission: false,
        successUpdatePermission: false,
        errorUpdatePermission: true,
        errorMessage: action.message,
      };
    case DELETE_SETTINGS_PERMISSION:
      return {
        ...state,
        loadingDeletePermission: true,
        successDeletePermission: false,
        errorDeletePermission: false,
      };
    case DELETE_SETTINGS_PERMISSION_SUCCESS:
      return {
        ...state,
        loadingDeletePermission: false,
        successDeletePermission: true,
        errorDeletePermission: false,
        messageDeletePermission: action.message,
      };
    case DELETE_SETTINGS_PERMISSION_FAILURE:
      return {
        ...state,
        loadingDeletePermission: false,
        successDeletePermission: false,
        errorDeletePermission: true,
        errorMessageDeletePermission: action.message,
      };
    case GET_SETTINGS_ROLES:
      return {
        ...state,
        loadingRoles: true,
      };
    case GET_SETTINGS_ROLES_SUCCESS:
      return {
        ...state,
        loadingRoles: false,
        roles: action.payload,
      };
    case GET_SETTINGS_ROLES_FAILURE:
      return {
        ...state,
        loadingRoles: false,
      };
    case GET_SETTINGS_ROLE_PERMISSION:
      return {
        ...state,
        loadingRoles: true,
      };
    case GET_SETTINGS_ROLE_PERMISSION_SUCCESS:
      return {
        ...state,
        loadingGetRolePermission: false,
        rolePermissions: action.payload,
      };
    case GET_SETTINGS_ROLE_PERMISSION_FAILURE:
      return {
        ...state,
        loadingGetRolePermission: false,
      };
    case UPDATE_SETTINGS_ROLE_PERMISSION:
      return {
        ...state,
        loadingUpdateRolePermission: true,
        successUpdateRolePermission: false,
        errorUpdateRolePermission: false,
      };
    case UPDATE_SETTINGS_ROLE_PERMISSION_SUCCESS:
      return {
        ...state,
        loadingUpdateRolePermission: false,
        successUpdateRolePermission: true,
        errorUpdateRolePermission: false,
        menu: action.payload,
      };
    case UPDATE_SETTINGS_ROLE_PERMISSION_FAILURE:
      return {
        ...state,
        loadingUpdateRolePermission: false,
        successUpdateRolePermission: false,
        errorUpdateRolePermission: true,
        errorMessage: action.message,
      };
    case GET_SETTINGS_ROLE:
      return {
        ...state,
        loadingGetRole: true,
        successGetRole: false,
        errorGetRole: false,
      };
    case GET_SETTINGS_ROLE_SUCCESS:
      return {
        ...state,
        loadingGetRole: false,
        successGetRole: true,
        errorGetRole: false,
        role: action.payload,
      };
    case GET_SETTINGS_ROLE_FAILURE:
      return {
        ...state,
        loadingGetRole: false,
        successGetRole: false,
        errorGetRole: true,
        errorMessage: action.message,
      };
    case GET_SETTINGS_MENUS:
      return {
        ...state,
        loadingMenus: true,
      };
    case GET_SETTINGS_MENUS_SUCCESS:
      return {
        ...state,
        loadingMenus: false,
        menus: action.payload,
      };
    case GET_SETTINGS_MENUS_FAILURE:
      return {
        ...state,
        loadingMenus: false,
      };
    case GET_SETTINGS_MENU:
      return {
        ...state,
        loadingGetMenu: true,
        successGetMenu: false,
        errorGetMenu: false,
      };
    case GET_SETTINGS_MENU_SUCCESS:
      return {
        ...state,
        loadingGetMenu: false,
        successGetMenu: true,
        errorGetMenu: false,
        menu: action.payload,
      };
    case GET_SETTINGS_MENU_FAILURE:
      return {
        ...state,
        loadingGetMenu: false,
        successGetMenu: false,
        errorGetMenu: true,
        errorMessage: action.message,
      };
    case CREATE_SETTINGS_MENU:
      return {
        ...state,
        loadingCreateMenu: true,
        successCreateMenu: false,
        errorCreateMenu: false,
      };
    case CREATE_SETTINGS_MENU_SUCCESS:
      return {
        ...state,
        loadingCreateMenu: false,
        successCreateMenu: true,
        errorCreateMenu: false,
        menu: action.payload,
      };
    case CREATE_SETTINGS_MENU_FAILURE:
      return {
        ...state,
        loadingCreateMenu: false,
        successCreateMenu: false,
        errorCreateMenu: true,
        errorMessage: action.message,
      };
    case DELETE_SETTINGS_MENU:
      return {
        ...state,
        loadingDeleteMenu: true,
        successDeleteMenu: false,
        errorDeleteMenu: false,
      };
    case UPDATE_SETTINGS_MENU:
      return {
        ...state,
        loadingUpdateMenu: true,
        successUpdateMenu: false,
        errorUpdateMenu: false,
      };
    case UPDATE_SETTINGS_MENU_SUCCESS:
      return {
        ...state,
        loadingUpdateMenu: false,
        successUpdateMenu: true,
        errorUpdateMenu: false,
        menu: action.payload,
      };
    case UPDATE_SETTINGS_MENU_FAILURE:
      return {
        ...state,
        loadingUpdateMenu: false,
        successUpdateMenu: false,
        errorUpdateMenu: true,
        errorMessage: action.message,
      };
    case DELETE_SETTINGS_MENU_SUCCESS:
      return {
        ...state,
        loadingDeleteMenu: false,
        successDeleteMenu: true,
        errorDeleteMenu: false,
        messageDeleteMenu: action.message,
      };
    case DELETE_SETTINGS_MENU_FAILURE:
      return {
        ...state,
        loadingDeleteMenu: false,
        successDeleteMenu: false,
        errorDeleteMenu: true,
        errorMessageDeleteMenu: action.message,
      };
    case ORDERING_SETTINGS_MENU:
      return {
        ...state,
        loadingOrderingMenu: true,
        successOrderingMenu: false,
        errorOrderingMenu: false,
      };
    case ORDERING_SETTINGS_MENU_SUCCESS:
      return {
        ...state,
        loadingOrderingMenu: false,
        successOrderingMenu: true,
        errorOrderingMenu: false,
      };
    case ORDERING_SETTINGS_MENU_FAILURE:
      return {
        ...state,
        loadingOrderingMenu: false,
        successOrderingMenu: false,
        errorOrderingMenu: true,
        errorMessage: action.message,
      };
    case GET_USERS:
      return {
        ...state,
        loading: true,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      };
    case GET_USERS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_USER:
      return {
        ...state,
        loadingGetUser: true,
        successGetUser: false,
        errorGetUser: false,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        loadingGetUser: false,
        successGetUser: true,
        errorGetUser: false,
        user: action.payload,
      };
    case GET_USER_FAILURE:
      return {
        ...state,
        loadingGetUser: false,
        successGetUser: false,
        errorGetUser: true,
        errorMessage: action.message,
      };
    case CREATE_USER:
      return {
        ...state,
        loadingCreateUser: true,
        successCreateUser: false,
        errorCreateUser: false,
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        loadingCreateUser: false,
        successCreateUser: true,
        errorCreateUser: false,
        user: action.payload,
      };
    case CREATE_USER_FAILURE:
      return {
        ...state,
        loadingCreateUser: false,
        successCreateUser: false,
        errorCreateUser: true,
        errorMessage: action.message,
      };
    case UPDATE_USER:
      return {
        ...state,
        loadingUpdateUser: true,
        successUpdateUser: false,
        errorUpdateUser: false,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loadingUpdateUser: false,
        successUpdateUser: true,
        errorUpdateUser: false,
        user: action.payload,
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        loadingUpdateUser: false,
        successUpdateUser: false,
        errorUpdateUser: true,
        errorMessage: action.message,
      };
    case DELETE_USER:
      return {
        ...state,
        loadingDeleteUser: true,
        successDeleteUser: false,
        errorDeleteUser: false,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loadingDeleteUser: false,
        successDeleteUser: true,
        errorDeleteUser: false,
        messageDeleteUser: action.message,
      };
    case DELETE_USER_FAILURE:
      return {
        ...state,
        loadingDeleteUser: false,
        successDeleteUser: false,
        errorDeleteUser: true,
        errorMessageDeleteUser: action.message,
      };
    case OPTION_USERS:
      return {
        ...state,
        loadingOptionUser: true,
      };
    case OPTION_USERS_SUCCESS:
      return {
        ...state,
        loadingOptionUser: false,
        optionUsers: action.payload,
      };
    case OPTION_USERS_FAILURE:
      return {
        ...state,
        loadingOptionUser: false,
      };
    case OPTION_ROLES:
      return {
        ...state,
        loadingOptionRole: true,
      };
    case OPTION_ROLES_SUCCESS:
      return {
        ...state,
        loadingOptionRole: false,
        optionRoles: action.payload,
      };
    case OPTION_ROLES_FAILURE:
      return {
        ...state,
        loadingOptionRole: false,
      };
    case GET_NOTIFICATION:
      return {
        ...state,
        loadingGetNotification: true,
        errorGetNotification: false,
        successGetNotification: false,
      };
    case GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loadingGetNotification: false,
        errorGetNotification: false,
        successGetNotification: true,
        notification: action.payload,
        notificationTotal: action.total
      };
    case GET_NOTIFICATION_FAILURE:
      return {
        ...state,
        loadingGetNotification: false,
        errorGetNotification: true,
        successGetNotification: false,
      };
    case MARK_AS_READ:
      return {
        ...state,
        loadingGetNotification: true,
        errorGetNotification: false,
        successGetNotification: false,
      };
    case MARK_AS_READ_SUCCESS:
      return {
        ...state,
        loadingGetNotification: false,
        errorGetNotification: false,
        successGetNotification: true,
      };
    case MARK_AS_READ_FAILURE:
      return {
        ...state,
        loadingGetNotification: false,
        errorGetNotification: true,
        successGetNotification: false,
      };
    default:
      return state;
  }
};

export default settingsReducer;
