import {
  ConsumersDispatchTypes,
  ConsumersType,
  GET_CONSUMERS,
  GET_CONSUMERS_FAILURE,
  GET_CONSUMERS_SUCCESS,
  GET_CONSUMER_FILTER,
} from './consumers-types';

interface DefaultStateI {
  loading: boolean;
  error?: boolean;
  messages?: any;

  consumers: ConsumersType;
  filterQuery: any;
}

const defaultState: DefaultStateI = {
  loading: false,
  consumers: {
    data: [],
  },
  filterQuery: {},
};

const consumersReducer = (state: DefaultStateI = defaultState, action: ConsumersDispatchTypes): DefaultStateI => {
  switch (action.type) {
    case GET_CONSUMERS:
      return {
        ...state,
        loading: true,
      };
    case GET_CONSUMERS_SUCCESS:
      return {
        ...state,
        loading: false,
        consumers: action.payload,
      };
    case GET_CONSUMERS_FAILURE:
      return {
        ...state,
        loading: false,
        messages: action.message,
      };
    case GET_CONSUMER_FILTER:
      return {
        ...state,
        filterQuery: action.payload,
      };
    default:
      return state;
  }
};

export default consumersReducer;
